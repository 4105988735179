import {
  updateUserDetails,
  getUser,
  resourcesHarvest,
  collectDaily,
  collectRefIcome,
  updateSubscribeTask,
  updateSubscribePartnersTask,
  updateStats,
  getMatterToTokenRate,
  getPoolBonusValue,
  updatePool,
  updateResetBonusValue,
  getCasinoBonusValue,
  updateCasino,
} from "store/slices/profileSlice";
import { fetchRequest } from "../tools/fetchTools";
import { getbuildings } from "store/slices/buildingsSlice";

const getDataUrl = "account/";
const renameUserUrl = "account/rename/";
const harvestUrl = "harvest/";
const getBonusUrl = "ads/bonus/";
const collectDailyUrl = "tasks/dailylogin/";
const collectRefIncomeUrl = "referalbonus/";
const subscribeTaskUrl = "tasks/subscribe/";
const subscribePartnersTaskUrl = "tasks/subscribe_partners/";
const subscribeClickLinkBotUrl = "tasks/subscribe_partners/click_link_bot/";
const rewardTraffyTaskUrl = "tasks/traffy/";
const getMatterToTokenRateUrl = "exchange_matter_2_tokens/exchange_rate/";
const getPoolBonusValueUrl = "time_special_building_matter_pool_1/bonus_value/";
const getResetBonusValueUrl = "reset/bonus_value/";
const resetProgressUrl = "reset/";
const poolMatterBonusUrl = "time_special_building_matter_pool_1/";
const changeMatterToTokenUrl = "exchange_matter_2_tokens/";
const getCasinoBonusValueUrl = "time_special_building_casino/bonus_value/";
const collectCasinoBonusUrl = "time_special_building_casino/";

export const getData =
  ({ tgId, avatar, siteLanguage }) =>
  async (dispatch) => {
    try {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 8000);
      });
      const dataPromise = fetchRequest(getDataUrl, tgId, siteLanguage);
      const data = await Promise.race([dataPromise, timeoutPromise]);

      if (data) {
        const {
          buildings,
          next_building,
          time_after_harvest,
          quantity_harvest,
          max_time_harvest,
          time_after_intermediate_harvest,
          timer_speed_bonus,
          date_permanent_bonus,
          value_resources_bonus,
          step_permanent_bonus,
          value_permanent_bonus,
          ref_link,
          ref_checkpoint,
          summary_income,
          ref_resources,
          ref_tokens,
          count_second_level_referrals,
          adsgram_bonus_value,
          resources,
          matter,
          tokens,
          pools,
          tasks,
          reset,
        } = data;

        dispatch(
          getUser({
            tgData: { tgId, avatar },
            time_after_harvest,
            quantity_harvest,
            time_after_intermediate_harvest,
            max_time_harvest,
            timer_speed_bonus,
            summary_income,
            step_permanent_bonus,
            date_permanent_bonus,
            ref_link,
            ref_checkpoint,
            ref_resources,
            ref_tokens,
            count_second_level_referrals,
            value_permanent_bonus,
            value_resources_bonus,
            adsgram_bonus_value,
            dailylogin: tasks.dailylogin,
            subscribe: tasks.subscribe,
            isPoolEnable: pools.time_special_building_matter_pool_1.enable,
            poolTimer:
              pools.time_special_building_matter_pool_1
                .time_special_building_matter_pool_1_ms,
            isCasinoEnable: pools.time_special_building_casino.enable,
            casinoTimer:
              pools.time_special_building_casino
                .time_special_building_casino_pool_ms,
            subscribe_partners: tasks.subscribe_partners,
            stats: {
              resources,
              matter,
              tokens,
            },
            resetEnable: reset.enable,
          })
        );

        dispatch(
          getbuildings([
            ...buildings,
            ...next_building.map((item) => ({ ...item, notBuyed: true })),
          ])
        );
      }
    } catch (error) {
      throw error;
    }
    // eslint-disable-next-line no-undef
  };

export const renameUser = () => async (dispatch, getState) => {
  try {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    const data = await fetchRequest(renameUserUrl, tgId, siteLanguage, "PUT", {
      new_name: "newName",
    });
    if (data) {
    }
  } catch (error) {
    console.error("error", error);
  }
  // eslint-disable-next-line no-undef
};

export const harvest = (isWithAd) => async (dispatch, getState) => {
  try {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    let data = null;
    if (!isWithAd) {
      data = await fetchRequest(harvestUrl, tgId, siteLanguage, "PUT");
    } else {
      data = await fetchBonus(tgId, siteLanguage, getBonusTypes.harvest, true);
    }

    if (data && data.account) {
      const { time_after_harvest, resources, quantity_harvest } = data.account;
      dispatch(
        resourcesHarvest({ time_after_harvest, resources, quantity_harvest })
      );
    }
  } catch (error) {
    console.error("error", error);
  }
  // eslint-disable-next-line no-undef
};

export const getBonusTypes = {
  permanent: "permanent",
  speed: "speed",
  resources: "resources",
  harvest: "harvest",
  upgrade: "upgrade",
};

export const fetchBonus = async (
  tgId,
  siteLanguage,
  type,
  is_ad_bonus,
  building_id = 0
) => {
  const data = await fetchRequest(getBonusUrl, tgId, siteLanguage, "POST", {
    bonus_type: type,
    cost: 0.0,
    is_ad_bonus,
    building_id,
  });

  return data;
};

export const getSubscribeTaskIncome = async (
  tgId,
  type,
  is_ad_bonus,
  building_id = 0
) => {
  const data = await fetchRequest(getBonusUrl, tgId, "POST", {
    bonus_type: type,
    cost: 0.0,
    is_ad_bonus,
    building_id,
  });

  return data;
};

export const getBonus = (type, is_ad_bonus) => async (dispatch, getState) => {
  try {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    const data = await fetchBonus(tgId, siteLanguage, type, is_ad_bonus);

    if (!data.account) return;

    const {
      time_after_harvest,
      quantity_harvest,
      time_after_intermediate_harvest,
      timer_speed_bonus,
      summary_income,
      max_time_harvest,
      step_permanent_bonus,
      date_permanent_bonus,
      value_permanent_bonus,
      value_resources_bonus,
      resources,
      matter,
      tokens,
    } = data.account;

    dispatch(
      updateUserDetails({
        stats: {
          resources,
          matter,
          tokens,
        },
        time_after_harvest,
        quantity_harvest,
        time_after_intermediate_harvest,
        timer_speed_bonus,
        step_permanent_bonus,
        date_permanent_bonus,
        value_permanent_bonus,
        value_resources_bonus,
        summary_income,
        max_time_harvest,
      })
    );
  } catch (error) {
    console.error("error", error);
  }
  // eslint-disable-next-line no-undef
};

export const fetchDailyCollect = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  const data = await fetchRequest(collectDailyUrl, tgId, siteLanguage, "POST");
  dispatch(
    collectDaily({
      stats: {
        resources: data.account.resources,
        matter: data.account.matter,
        tokens: data.account.tokens,
      },
      dailylogin: data.account.tasks.dailylogin,
    })
  );
};

export const fetchColectRefIncome = (bonus) => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      collectRefIncomeUrl + (bonus ? `?bonus=True` : ""),
      tgId,
      siteLanguage,
      "GET"
    );
    dispatch(
      collectRefIcome({
        stats: {
          resources: data.account.resources,
          matter: data.account.matter,
          tokens: data.account.tokens,
        },
        ref_tokens: data.account.ref_tokens,
        ref_resources: data.account.ref_resources,
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const subscribeTask = (url) => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      subscribeTaskUrl + `?channel_url=${url}`,
      tgId,
      siteLanguage,
      "POST"
    );
    const { resources, matter, tokens } = data.account;
    dispatch(
      updateSubscribeTask({
        key: url,
        data: data.account.tasks.subscribe[url],
        stats: { resources, matter, tokens },
      })
    );
  } catch (error) {
    return error;
  }
};

export const rewardTraffyTask = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      rewardTraffyTaskUrl,
      tgId,
      siteLanguage,
      "POST"
    );
    const { resources, matter, tokens } = data.account;
    dispatch(updateStats({ resources, matter, tokens }));
  } catch (error) {
    return error;
  }
};

export const subscribePartnersTask =
  (url, key) => async (dispatch, getState) => {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    const params = new URLSearchParams({
      channel_url: url,
    });
    try {
      const data = await fetchRequest(
        `${subscribePartnersTaskUrl}?${params.toString()}`,
        tgId,
        siteLanguage,
        "POST"
      );

      const { resources, matter, tokens } = data.account;
      dispatch(
        updateSubscribePartnersTask({
          key,
          data: data.account.tasks.subscribe_partners[key],
          stats: { resources, matter, tokens },
        })
      );
    } catch (error) {
      return error;
    }
  };

export const subscribeClickLinkBot =
  (url, key) => async (dispatch, getState) => {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;

    const params = new URLSearchParams({
      task_link: url,
    });
    try {
      const data = await fetchRequest(
        `${subscribeClickLinkBotUrl}?${params.toString()}`,
        tgId,
        siteLanguage,
        "POST"
      );
      
      const { resources, matter, tokens } = data.account;
   
      dispatch(
        updateSubscribePartnersTask({
          key,
          data: data.account.tasks.subscribe_partners[key],
          stats: { resources, matter, tokens },
        })
      );
    } catch (error) {
      return error;
    }
  };

export const fetchMatterToTokenRate =
  (tgIdP, siteLanguageP) => async (dispatch, getState) => {
    const tgId = tgIdP || getState().profile.tgData.tgId;
    const siteLanguage = siteLanguageP || getState().ui.siteLanguage;
    try {
      const data = await fetchRequest(
        `${getMatterToTokenRateUrl}`,
        tgId,
        siteLanguage
      );

      dispatch(getMatterToTokenRate(data?.exchange_rate || 0));
    } catch (error) {}
  };

export const fetchPoolBonusValue = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;
  try {
    const data = await fetchRequest(
      `${getPoolBonusValueUrl}`,
      tgId,
      siteLanguage
    );
    dispatch(getPoolBonusValue(data.bonus_amount));
  } catch (error) {}
};

export const fetchCasinoBonusValue = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;
  try {
    const data = await fetchRequest(
      `${getCasinoBonusValueUrl}`,
      tgId,
      siteLanguage
    );
    dispatch(getCasinoBonusValue(data.bonus_amount));
  } catch (error) {}
};

export const fetchResetBonusValue = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;
  try {
    const data = await fetchRequest(
      `${getResetBonusValueUrl}`,
      tgId,
      siteLanguage,
      "POST",
      {
        id_tgrm: tgId,
      }
    );

    dispatch(updateResetBonusValue(data.bonus_amount));
  } catch (error) {}
};
export const resetProgress = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;
  try {
    const data = await fetchRequest(
      `${resetProgressUrl}`,
      tgId,
      siteLanguage,
      "POST",
      {
        id_tgrm: tgId,
      }
    );

    const {
      buildings,
      next_building,
      time_after_harvest,
      quantity_harvest,
      max_time_harvest,
      time_after_intermediate_harvest,
      timer_speed_bonus,
      date_permanent_bonus,
      value_resources_bonus,
      step_permanent_bonus,
      value_permanent_bonus,
      ref_link,
      ref_checkpoint,
      summary_income,
      ref_resources,
      ref_tokens,
      count_second_level_referrals,
      resources,
      matter,
      tokens,
      pools,
      tasks,
      reset,
    } = data.account;

    dispatch(
      getUser({
        time_after_harvest,
        quantity_harvest,
        time_after_intermediate_harvest,
        max_time_harvest,
        timer_speed_bonus,
        summary_income,
        step_permanent_bonus,
        date_permanent_bonus,
        ref_link,
        ref_checkpoint,
        ref_resources,
        ref_tokens,
        count_second_level_referrals,
        value_permanent_bonus,
        value_resources_bonus,
        dailylogin: tasks.dailylogin,
        subscribe: tasks.subscribe,
        isPoolEnable: pools.time_special_building_matter_pool_1.enable,
        poolTimer:
          pools.time_special_building_matter_pool_1
            .time_special_building_matter_pool_1_ms,
        subscribe_partners: tasks.subscribe_partners,
        stats: {
          resources,
          matter,
          tokens,
        },
        resetEnable: reset.enable,
      })
    );

    dispatch(
      getbuildings([
        ...buildings,
        ...next_building.map((item) => ({ ...item, notBuyed: true })),
      ])
    );
  } catch (error) {}
};

export const poolMatterBonus = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;
  try {
    const data = await fetchRequest(
      `${poolMatterBonusUrl}`,
      tgId,
      siteLanguage,
      "POST"
    );
    const { resources, matter, tokens, pools } = data.account;
    dispatch(
      updatePool({
        stats: { resources, matter, tokens },
        isPoolEnable: false,
        poolTimer:
          pools.time_special_building_matter_pool_1
            .time_special_building_matter_pool_1_ms,
      })
    );
  } catch (error) {}
};

export const collectCasinoBonus = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;
  try {
    const data = await fetchRequest(
      `${collectCasinoBonusUrl}`,
      tgId,
      siteLanguage,
      "POST"
    );
    const { resources, matter, tokens, pools } = data.account;
    dispatch(
      updateCasino({
        stats: { resources, matter, tokens },
        isCasinoEnable: false,
        casinoTimer:
          pools.time_special_building_casino
            .time_special_building_casino_pool_ms,
      })
    );
  } catch (error) {}
};

export const changeMatterToToken = (value) => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      `${changeMatterToTokenUrl}`,
      tgId,
      siteLanguage,
      "POST",
      {
        id_tgrm: tgId,
        matter_to_exchange: +value,
      }
    );

    const { resources, matter, tokens } = data.account;
    dispatch(
      updateStats({
        resources,
        matter,
        tokens,
      })
    );
  } catch (error) {
    throw error;
  }
};
