import React from "react";
import { useSelector } from "react-redux";

import { collectCasinoBonus } from "store/actions/profile";
import BuildsGetBonusModal from "../BuildsGetBonusModal/BuildsGetBonusModal";

const BuildsCasinoModal = ({ onClose, activePopup }) => {
  const casinoBonusValue = useSelector((state) => state.profile.casinoBonusValue);
  const casinoTimer = useSelector((state) => state.profile.casinoTimer);
  const isCasinoEnable = useSelector((state) => state.profile.isCasinoEnable);
console.log({casinoBonusValue});

  return (
    <BuildsGetBonusModal
      activePopup={activePopup}
      onClose={onClose}
      collectBonus={collectCasinoBonus}
      timer={casinoTimer}
      bonusValue={casinoBonusValue}
      isEnable={isCasinoEnable}
      incomeType={"tokens"}
    />
  );
};

export default BuildsCasinoModal;
