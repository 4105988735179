import React from "react";
import "./MobileNav.scss";

import { NavLink, useLocation } from "react-router-dom";
import Svg from "components/layout/Svg/Svg";
import {
  menuAchievmentsIcon,
  menuBgActiveIcon,
  menuBgDefaultIcon,
  menuBottomIcon,
  menuBuildsIcon,
  menuFriendsIcon,
  menuMainIcon,
  menuTasksIcon,
  mobileMenuBorderIcon,
  mobMenuTopIcon,
} from "assets/svg";
import {
  buildsPagePath,
  friendsPagePath,
  hackTerminalPagePath,
  mainPagePath,
  tasksPagePath,
} from "router/path";
import Tooltip from "components/layout/Tooltip/Tooltip";
import { useTooltip } from "hooks/usetooltip";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";

const links = [
  {
    // link: achievmentsPagePath,
    icon: menuAchievmentsIcon,
  },
  {
    link: buildsPagePath,
    icon: menuBuildsIcon,
  },
  {
    link: mainPagePath,
    icon: menuMainIcon,
  },
  {
    link: tasksPagePath,
    icon: menuTasksIcon,
  },
  {
    link: friendsPagePath,
    icon: menuFriendsIcon,
  },
];

const inDevelopmentText = TRANSLATIONS.inDevelopmentText;

const MobileNav = () => {
  const location = useLocation();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const { show, openTooltip } = useTooltip();

  const isMenuHidden = location.pathname === hackTerminalPagePath;

  if (isMenuHidden) return null;

  return (
    <>
      <nav className="mobile-nav">
        <div className="mobile-nav__wrap">
          <div className="mobile-nav__head f-center-center">
            <Svg id={mobMenuTopIcon} />
          </div>
          <ul className="mobile-menu f-center-center">
            {links.map(({ link, icon }, index) => {
              const content = (
                <>
                  <div className="mobile-menu__icon">
                    <Svg id={icon} />
                  </div>
                  <div className="mobile-menu__bg">
                    <div className="mobile-menu__bg-default">
                      <Svg id={menuBgDefaultIcon} />
                    </div>
                    {location.pathname === link && (
                      <div className="mobile-menu__bg-active">
                        <Svg id={menuBgActiveIcon} />
                      </div>
                    )}
                  </div>
                  <div className="mobile-menu__border">
                    <Svg id={mobileMenuBorderIcon} />
                  </div>
                </>
              );

              return (
                <li className="mobile-menu__item" key={index}>
                  {link ? (
                    <NavLink
                      to={link}
                      className="mobile-menu__link"
                      activeclassname="active"
                    >
                      {content}
                    </NavLink>
                  ) : (
                    <button onClick={openTooltip} className="mobile-menu__link">
                      {content}
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="mobile-nav__footer f-center-center">
            <Svg id={menuBottomIcon} />
          </div>
        </div>
      </nav>
      <Tooltip show={show} text={inDevelopmentText[siteLanguage]} />
    </>
  );
};

export default MobileNav;
