import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tgData: {
    tgId: "",
    avatar: "",
  },
  stats: {
    resources: 0,
    matter: 0,
    tokens: 0,
  },
  time_after_harvest: 0,
  quantity_harvest: 0,
  time_after_intermediate_harvest: 0,
  timer_speed_bonus: 0,
  summary_income: 0,
  max_time_harvest: 0,
  value_permanent_bonus: 0,
  value_resources_bonus: 0,
  date_permanent_bonus: 0,
  step_permanent_bonus: 0,
  adsgram_bonus_value: 0,
  ref_link: "",
  ref_resources: 0,
  ref_tokens: 0,
  count_second_level_referrals: 0,
  ref_checkpoint: {
    reset: false,
    harvest: false,
    registration: false,
    count_reset: 0,
    count_harvest: 0,
    count_registration: 0,
  },
  dailylogin: {
    day: 0,
    date: 0,
    enable: false,
    end_of_day: 0,
    total_bonus: 0,
  },
  subscribe: {},
  subscribe_partners: {},
  matterToTonRate: 0,
  isPoolEnable: false,
  poolBonusValue: 0,
  poolTimer: 0,
  isCasinoEnable: false,
  casinoBonusValue: 0,
  casinoTimer: 0,
  resetEnable: false,
  resetBonusValue: 0,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateUserStats(state, { payload }) {
      const {
        time_after_harvest,
        summary_income,
        value_resources_bonus,
        subscribe,
        ...stats
      } = payload;
      state.stats = stats;
      state.time_after_harvest = time_after_harvest;
      state.summary_income = summary_income;
      state.value_resources_bonus = value_resources_bonus;
      state.subscribe = subscribe;
    },
    getUser(state, { payload }) {
      if (payload.tgData) state.tgData = payload.tgData;
      state.stats = payload.stats;
      state.time_after_harvest = payload.time_after_harvest;
      state.quantity_harvest = payload.quantity_harvest;
      state.max_time_harvest = payload.max_time_harvest;
      state.time_after_intermediate_harvest =
        payload.time_after_intermediate_harvest;
      state.timer_speed_bonus = payload.timer_speed_bonus;
      state.summary_income = payload.summary_income;
      state.value_permanent_bonus = payload.value_permanent_bonus;
      state.value_resources_bonus = payload.value_resources_bonus;
      state.date_permanent_bonus = payload.date_permanent_bonus;
      state.step_permanent_bonus = payload.step_permanent_bonus;
      state.adsgram_bonus_value = payload.adsgram_bonus_value;
      state.ref_link = payload.ref_link;
      state.ref_checkpoint = payload.ref_checkpoint;
      state.ref_resources = payload.ref_resources;
      state.ref_tokens = payload.ref_tokens;
      state.dailylogin = payload.dailylogin;
      state.subscribe = payload.subscribe;
      state.isPoolEnable = payload.isPoolEnable;
      state.poolTimer = payload.poolTimer;
      state.isCasinoEnable = payload.isCasinoEnable;
      state.casinoTimer = payload.casinoTimer;
      state.resetEnable = payload.resetEnable;
      state.subscribe_partners = payload.subscribe_partners;
      state.count_second_level_referrals = payload.count_second_level_referrals;
    },
    resourcesHarvest(state, { payload }) {
      state.time_after_harvest = payload.time_after_harvest;
      state.stats.resources = payload.resources;
      state.quantity_harvest = payload.quantity_harvest;
    },
    updateStats(state, { payload }) {
      state.stats = payload;
    },
    getPoolBonusValue(state, { payload }) {
      state.poolBonusValue = payload;
    },
    getCasinoBonusValue(state, { payload }) {
      state.casinoBonusValue = payload;
    },
    updateResetBonusValue(state, { payload }) {
      state.resetBonusValue = payload;
    },
    updateResetEnable(state, { payload }) {
      state.resetEnable = payload;
    },
    updatePool(state, { payload }) {
      state.stats = payload.stats;
      state.isPoolEnable = payload.isPoolEnable;
      state.poolTimer = payload.poolTimer;
    },
    updateCasino(state, { payload }) {
      state.stats = payload.stats;
      state.isCasinoEnable = payload.isCasinoEnable;
      state.casinoTimer = payload.casinoTimer;
    },
    updateHarvest(state) {
      state.time_after_harvest = state.time_after_harvest + 1000;
      state.quantity_harvest =
        state.quantity_harvest + state.summary_income / 3600;
    },
    updateUserDetails(state, { payload }) {
      state.stats = payload.stats;
      state.time_after_harvest = payload.time_after_harvest;
      state.quantity_harvest = payload.quantity_harvest;
      state.time_after_intermediate_harvest =
        payload.time_after_intermediate_harvest;
      state.timer_speed_bonus = payload.timer_speed_bonus;
      state.summary_income = payload.summary_income;
      state.max_time_harvest = payload.max_time_harvest;
      state.value_permanent_bonus = payload.value_permanent_bonus;
      state.value_resources_bonus = payload.value_resources_bonus;
      state.date_permanent_bonus = payload.date_permanent_bonus;
      state.step_permanent_bonus = payload.step_permanent_bonus;
    },
    increaseSpeedTimer(state) {
      state.timer_speed_bonus = state.timer_speed_bonus
        ? state.timer_speed_bonus - 1000
        : 0;
    },
    increasePermanentTimer(state) {
      state.date_permanent_bonus = state.date_permanent_bonus
        ? state.date_permanent_bonus - 1000
        : 0;
    },
    increaseDailyTimer(state) {
      state.dailylogin.end_of_day = state.dailylogin.end_of_day - 1000;
    },
    increasePoolTimer(state) {
      state.poolTimer = state.poolTimer - 1000;
    },
    increaseCasinoTimer(state) {
      state.casinoTimer = state.casinoTimer - 1000;
    },
    collectDaily(state, { payload }) {
      state.stats = payload.stats;
      state.dailylogin = payload.dailylogin;
    },
    collectRefIcome(state, { payload }) {
      state.ref_tokens = payload.ref_tokens;
      state.ref_resources = payload.ref_resources;
      state.stats = payload.stats;
    },
    updateSubscribeTask(state, { payload }) {
      state.subscribe[payload.key] = payload.data;
      if (payload.stats) {
        state.stats = payload.stats;
      }
    },
    updateSubscribePartnersTask(state, { payload }) {
      state.subscribe_partners[payload.key] = payload.data;
      if (payload.stats) {
        state.stats = payload.stats;
      }
    },
    getMatterToTokenRate(state, { payload }) {
      state.matterToTonRate = payload;
    },
    increaseSubscribeTimers(state) {
      state.subscribe = Object.keys(state.subscribe).reduce((acc, cur) => {
        acc[cur] = {
          ...state.subscribe[cur],
          time_left:
            state.subscribe[cur].time_left &&
            state.subscribe[cur].time_left - 1000,
        };

        return acc;
      }, {});
      state.subscribe_partners = Object.keys(state.subscribe_partners).reduce(
        (acc, cur) => {
          acc[cur] = {
            ...state.subscribe_partners[cur],
            time_left:
              state.subscribe_partners[cur].time_left &&
              state.subscribe_partners[cur].time_left - 1000,
          };

          return acc;
        },
        {}
      );
    },
  },
});

export const {
  getUser,
  resourcesHarvest,
  updateUserStats,
  updateHarvest,
  updateUserDetails,
  increaseSpeedTimer,
  increasePermanentTimer,
  increaseDailyTimer,
  collectDaily,
  collectRefIcome,
  updateSubscribeTask,
  updateSubscribePartnersTask,
  increaseSubscribeTimers,
  updateStats,
  getMatterToTokenRate,
  getPoolBonusValue,
  getCasinoBonusValue,
  increasePoolTimer,
  increaseCasinoTimer,
  updatePool,
  updateCasino,
  updateResetBonusValue,
  updateResetEnable,
} = profileSlice.actions;

export default profileSlice.reducer;
