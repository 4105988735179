import { LANGUAGES } from "constants/languages";

export const TRANSLATIONS = {
  inDevelopmentText: {
    [LANGUAGES.eng]: "In development",
    [LANGUAGES.rus]: "В разработке",
  },
  resourcePopup: {
    orText: {
      [LANGUAGES.eng]: "or",
      [LANGUAGES.rus]: "или",
    },
    availableForCollectionText: {
      [LANGUAGES.eng]: "Available for collection",
      [LANGUAGES.rus]: "Доступно к сбору",
    },
    viewAdText: {
      [LANGUAGES.eng]: (
        <>
          View <br />
          advertisement
        </>
      ),
      [LANGUAGES.rus]: (
        <>
          Посмотреть <br />
          рекламу
        </>
      ),
    },
    adsUnavailableText: {
      [LANGUAGES.eng]: "Ads are currently unavailable",
      [LANGUAGES.rus]: "Сейчас реклама недоступна",
    },
    collectText: {
      [LANGUAGES.eng]: "Collect",
      [LANGUAGES.rus]: "Собрать",
    },
    willBeAvailableText: {
      [LANGUAGES.eng]: "Will be available in",
      [LANGUAGES.rus]: "Будет доступно через",
    },
  },
  settingsPopup: {
    settingsText: {
      [LANGUAGES.eng]: "Settings",
      [LANGUAGES.rus]: "Настройки",
    },
    languageText: {
      [LANGUAGES.eng]: "Language",
      [LANGUAGES.rus]: "Язык",
    },
    tutorialText: {
      [LANGUAGES.eng]: "Tutorial",
      [LANGUAGES.rus]: "Обучение",
    },
    walletText: {
      [LANGUAGES.eng]: "Wallet",
      [LANGUAGES.rus]: "Кошелек",
    },
  },
  tutorialPopup: {
    closeText: {
      [LANGUAGES.eng]: "Close",
      [LANGUAGES.rus]: "Закрыть",
    },
    nextText: {
      [LANGUAGES.eng]: "Next",
      [LANGUAGES.rus]: "Далее",
    },
  },
  home: {
    resourcesText: {
      [LANGUAGES.eng]: "Resources",
      [LANGUAGES.rus]: "Ресурсы",
    },
    matterText: {
      [LANGUAGES.eng]: "Dark matter",
      [LANGUAGES.rus]: "Темная материя",
    },
    tokenText: {
      [LANGUAGES.eng]: "Tokens",
      [LANGUAGES.rus]: "Жетоны",
    },
    extractiontimeText: {
      [LANGUAGES.eng]: "Collecting time",
      [LANGUAGES.rus]: "Время добычи",
    },
    resourcesMinedText: {
      [LANGUAGES.eng]: "Resources mined",
      [LANGUAGES.rus]: "Добыто ресурсов",
    },
    collectText: {
      [LANGUAGES.eng]: "Collect",
      [LANGUAGES.rus]: "Собрать",
    },
    permanentBonusText: {
      [LANGUAGES.eng]: "Permanent bonus",
      [LANGUAGES.rus]: "Постоянный бонус",
    },
    currentBonusText: {
      [LANGUAGES.eng]: "Current bonus",
      [LANGUAGES.rus]: "Текущий бонус",
    },
    resourceBonusText: {
      [LANGUAGES.eng]: "Resource Bonus",
      [LANGUAGES.rus]: "Бонус к ресурсам",
    },
    closeText: {
      [LANGUAGES.eng]: "Close",
      [LANGUAGES.rus]: "Закрыть",
    },
    conditionsNotMetText: {
      [LANGUAGES.eng]: "2nd collider upgrade needed",
      [LANGUAGES.rus]: "Необходим 2-й апгрейд коллайдера",
    },
    tutorial: {
      startText: {
        [LANGUAGES.eng]:
          "Hello, my name is Lessia, serial number ID, I am a copy of the first humanoid android. I will help you throughout the construction of the Ark - your city, an island of life among the endless deserts of the planet.",
        [LANGUAGES.rus]:
          "Приветствую, меня зовут Лессия, серийный номер ID, я копия первого человеко подобного андроида. Я буду помогать тебе на всем пути строительства Ковчега – твоего города, островка жизни среди бесконечных пустынь планеты.",
      },
      menuText: {
        [LANGUAGES.eng]: `Now you are in the main menu of the Ark management - your city. Well, for now there is only a dump that needs to be cleared, but you can do it! To start building, you need to go to the "City" section in the bottom menu, the second button from the left.`,
        [LANGUAGES.rus]:
          "Сейчас ты находишься в главном меню управления Ковчегом – твоего города. Ну как города, пока тут только свалка, которую нужно расчистить, но ты справишься! Чтобы начать строить необходимо перейти в раздел «Город» в нижнем меню, вторая кнопка слева.",
      },
      statsText: {
        [LANGUAGES.eng]:
          "These are your main assets, you will spend them on building absolutely everything: buildings, upgrades, special grand buildings and so on.",
        [LANGUAGES.rus]:
          "Это твои основные активы, ты будешь тратить их на строительство абсолютно всего: обычных зданий, улучшений, а также особых грандиозных зданий и так далее.",
      },
      resourceText: {
        [LANGUAGES.eng]:
          "First currency - resources, resources are an indicator of the basic economy of the Ark, more resources – more buildings, more buildings – more resources. Well, you get the idea.",
        [LANGUAGES.rus]:
          "Самая первая валюта – ресурсы, это показатель базовой экономики Ковчега, чем их больше, тем больше ты можешь построить, чтобы получить еще больше ресурсов и еще больше построить. Ну ты понял.  ",
      },
      matterText: {
        [LANGUAGES.eng]:
          "Second currency is dark matter. Great scientists of the past learned how to obtain dark matter from the surrounding space. This is an extremely valuable resource, a source of enormous energy, required for the construction of special structures and energy-intensive industries. Obtaining dark matter is extremely difficult and associated with enormous risks. There are rumors that many Arks disappeared from the face of the Earth due to careless attempts to obtain dark matter.",
        [LANGUAGES.rus]:
          "Вторая валюта – это темная материя. Великие ученые прошлого научились получать темную материю из окружающего пространства. Это крайне ценный ресурс, источник огромной энергии, требуется при строительстве особых сооружений и энергоемких производств. Получение темной материи крайне сложно и сопряжено с огромными рисками. Ходят слухи, что множество Ковчегов исчезло с лица Земли при неосторожных попытках получить темную материю.",
      },
      tokenText: {
        [LANGUAGES.eng]:
          "The third currency is tokens. Not even a currency, but luxury items, very rare and of great value due to the small amount. People are talking that before it was money. Money in the form of metal coins, sounds pretty weird, right?",
        [LANGUAGES.rus]:
          "Третья валюта – жетоны. Скорее это даже не валюта, а предметы роскоши, встречаются очень редко и представляют огромную ценность из-за малого количества. Говорят, раньше это было деньгами. Деньги в виде металлических монет, звучит довольно странно, правда?",
      },
      mostUsedStatText: {
        [LANGUAGES.eng]:
          "Most of your buildings produce resources that need to be collected regularly.",
        [LANGUAGES.rus]:
          "Большинство твоих зданий производит ресурсы, которые необходимо регулярно собирать.",
      },
      extractiontimeText: {
        [LANGUAGES.eng]:
          "This is a general timer from your last collection, resources do not accumulate for more than 6 hours, so check regularly how much has already accumulated. The maximum collection time can be improved in the future.",
        [LANGUAGES.rus]:
          "Здесь указан общий таймер с твоего последнего сбора, больше 6 часов ресурсы не копятся, поэтому регулярно проверяй, сколько уже набежало. Максимальное время сбора можно улучшить в дальнейшем.",
      },
      resourcesMinedText: {
        [LANGUAGES.eng]:
          "The total amount of resources available for collection is shown here.",
        [LANGUAGES.rus]:
          "Общее количество доступных к сбору ресурсов показано здесь.",
      },
      collectText: {
        [LANGUAGES.eng]:
          "To collect resources you need to press the button, it is big and round, you can’t miss.",
        [LANGUAGES.rus]:
          "Чтобы собрать ресурсы нужно нажать на кнопку, она большая и круглая, не промахнешься.",
      },
      mlmText: {
        [LANGUAGES.eng]:
          "An international MLM corporation operates in each Ark. People are talking that they zombify everyone with their advertising, but they pay a huge amount of resources for their advertising contracts and invest in the economy, so no one cares.",
        [LANGUAGES.rus]:
          "В каждом Ковчеге работает международная корпорация МЛМ. Говорят, что они зомбируют всех своей рекламой, но они платят за свои рекламные контракты огромное количество ресурсов и инвестируют в экономику, поэтому никому нет до этого дела. ",
      },
      permanentBonusText: {
        [LANGUAGES.eng]:
          "The permanent bonus is an important bonus, it increases your entire production by a small percentage, but it can be received every day. One bonus will not significantly affect your production, but dozens of such bonuses will significantly speed up the game.",
        [LANGUAGES.rus]:
          "Постоянный бонус – важный бонус, он увеличивает все твое производство на небольшой процент, но его можно его можно получать каждый день. Один бонус существенно не повлияет на твою добычу, но десятки таких бонусов значительно ускорят игру. ",
      },
      speedBonusText: {
        [LANGUAGES.eng]:
          "This bonus gives a large but short-term bonus, accelerating mining by two times for two hours. You can get it several times, and then the bonus duration will add up.",
        [LANGUAGES.rus]:
          "Этот бонус дает большой, но кратковременный бонус, на два часа ускоряя добычу в два раза. Можно получить несколько раз, и тогда время действия бонуса сложится.",
      },
      resourceBonusText: {
        [LANGUAGES.eng]:
          "Not enough resources? You can watch the ad and get resources that you will get in half an hour (active mining speed bonus is also taken into account, it is advisable to use it together).",
        [LANGUAGES.rus]:
          "Немного не хватает на улучшение? Можно просмотреть рекламу и получить ресурсы, которые ты добудешь за пол часа (активный бонус к скорости тоже учитывается, желательно использовать вместе с ним). ",
      },
      resetProgressText: {
        [LANGUAGES.eng]:
          "At a certain level of development, it will be possible to obtain dark matter. Many will have to be sacrificed, but it will be worth it.",
        [LANGUAGES.rus]:
          "На определенном уровне развития, можно будет получить темную материю. Многим придется пожертвовать, но оно того стоит. ",
      },
    },
  },
  buildings: {
    buildingsText: {
      [LANGUAGES.eng]: "Buildings",
      [LANGUAGES.rus]: "Здания",
    },
    cityImprovementsText: {
      [LANGUAGES.eng]: "City Improvements",
      [LANGUAGES.rus]: "Улучшения города",
    },
    specialBuildingsText: {
      [LANGUAGES.eng]: "Special buildings",
      [LANGUAGES.rus]: "Особые здания",
    },
    modeChangeText: {
      [LANGUAGES.eng]: (
        <>
          Mode
          <br />
          change
        </>
      ),
      [LANGUAGES.rus]: (
        <>
          Смена
          <br />
          режима
        </>
      ),
    },
    overallMiningSpeedText: {
      [LANGUAGES.eng]: "Overall mining speed (including all bonuses)",
      [LANGUAGES.rus]: "Общая скорость добычи (с учетом всех бонусов)",
    },
    hourText: {
      [LANGUAGES.eng]: "hour",
      [LANGUAGES.rus]: "час",
    },
    upgradesText: {
      [LANGUAGES.eng]: "Upgrades",
      [LANGUAGES.rus]: "Апгрейды",
    },
    lvlText: {
      [LANGUAGES.eng]: "lvl.",
      [LANGUAGES.rus]: "ур.",
    },
    forText: {
      [LANGUAGES.eng]: "for",
      [LANGUAGES.rus]: "за",
    },
    freeText: {
      [LANGUAGES.eng]: "Free",
      [LANGUAGES.rus]: "Бесплатно",
    },
    upgradeText: {
      [LANGUAGES.eng]: "upgrade",
      [LANGUAGES.rus]: "апгрейд",
    },
    buyText: {
      [LANGUAGES.eng]: "Buy",
      [LANGUAGES.rus]: "Купить",
    },
    viewAdText: {
      [LANGUAGES.eng]: "View advertisement",
      [LANGUAGES.rus]: "Посмотреть рекламу",
    },
    openText: {
      [LANGUAGES.eng]: "Open",
      [LANGUAGES.rus]: "Открыть",
    },
    improveText: {
      [LANGUAGES.eng]: "Improve",
      [LANGUAGES.rus]: "Улучшить",
    },
    levelText: {
      [LANGUAGES.eng]: "level",
      [LANGUAGES.rus]: "уровень",
    },
    tutorial: {
      startText: {
        [LANGUAGES.eng]:
          "You are in the Ark construction section. You will build flourishing paradise for your people from here.",
        [LANGUAGES.rus]:
          "Ты в разделе строительства Ковчега. Именно отсюда ты построишь цветущий рай для своих людей.",
      },
      buildingsText: {
        [LANGUAGES.eng]:
          "In the Buildings section, you can build and upgrade regular buildings that provide resources. When you reach a certain level of a building (25, 50, 75, 100, 150, and 200), you can upgrade it. Any upgrade increases the resource production of that building by 25%, but starting with the 3rd upgrade, the production of all buildings increases, and the 6th upgrade additionally increases the production of all buildings by 20%. These are very valuable upgrades, so if your progress has slowed down, go back to earlier buildings and necessarily make upgrades.",
        [LANGUAGES.rus]:
          "В разделе Здания строятся и улучшаются обычные здания, которые дают ресурсы. При достижении определенного уровня (25, 50, 75, 100, 150 и 200) можно сделать апгрейд. Любой апгрейд увеличивает производство ресурсов этого здания на 25%, однако, начиная с 3-го апгрейда, увеличивается производство всех зданий, 6-й апгрейд дополнительно увеличивает производство всех зданий на 20%. Это очень ценные апгрейды, поэтому если твой прогресс замедлился, вернись к более ранним зданиям и сделай их.",
      },
      cityImproovmentText: {
        [LANGUAGES.eng]:
          "In the city improvements section, you can build buildings for dark matter that give bonuses to all other buildings. But you'll have to get the dark matter from somewhere.",
        [LANGUAGES.rus]:
          "В разделе улучшений города ты сможешь построить за темную материю здания, дающие бонусы всем остальным зданиям. Но темную материю придется откуда-то достать.",
      },
      specialBuildingsText: {
        [LANGUAGES.eng]:
          "In the special buildings section you can build… special buildings! These are not necessarily expensive, but extremely important buildings that can significantly change the entire process. Some special buildings will earn you tokens, so be sure to build them!",
        [LANGUAGES.rus]:
          "В разделе особые здания ты сможешь построить… особые здания! Это необязательно дорогие, но крайне важные постройки, которые могут существенно изменить весь процесс.",
      },
    },
  },
  tasks: {
    regularTasksText: {
      [LANGUAGES.eng]: "Regular tasks",
      [LANGUAGES.rus]: "Обычные задания",
    },
    hackingTerminalText: {
      [LANGUAGES.eng]: "Hacking Terminal",
      [LANGUAGES.rus]: "Взлом терминала",
    },
    supportProjectText: {
      [LANGUAGES.eng]: "Support the project",
      [LANGUAGES.rus]: "Поддержать проект",
    },
    advancedTasksText: {
      [LANGUAGES.eng]: "Advanced tasks",
      [LANGUAGES.rus]: "Продвинутые задания",
    },
    specialTasksText: {
      [LANGUAGES.eng]: "Special tasks",
      [LANGUAGES.rus]: "Особые задания",
    },
    dailyLoginRewardText: {
      [LANGUAGES.eng]: "Daily Login Reward",
      [LANGUAGES.rus]: "Награда за ежедневный вход",
    },
    dayText: {
      [LANGUAGES.eng]: "Day",
      [LANGUAGES.rus]: "День",
    },

    collectText: {
      [LANGUAGES.eng]: "Collect",
      [LANGUAGES.rus]: "Собрать",
    },
    subscribeText: {
      [LANGUAGES.eng]: "Subscribe",
      [LANGUAGES.rus]: "Подписаться",
    },
    getText: {
      [LANGUAGES.eng]: "Get",
      [LANGUAGES.rus]: "Получить",
    },
    receivedText: {
      [LANGUAGES.eng]: "Received",
      [LANGUAGES.rus]: "Получено",
    },
    lvlText: {
      [LANGUAGES.eng]: "lvl",
      [LANGUAGES.rus]: "ур",
    },
    spenderText: {
      [LANGUAGES.eng]: "Spender",
      [LANGUAGES.rus]: "Транжира",
    },
    checkText: {
      [LANGUAGES.eng]: "Check",
      [LANGUAGES.rus]: "Проверять",
    },
    tutorialText: {
      [LANGUAGES.eng]:
        "In this section you can complete various tasks. These can be resources, achievements or something else. Most tasks can be completed multiple times, so come here often and collect rewards, so you can develop the Ark faster.",
      [LANGUAGES.rus]:
        "В этом разделе ты можешь выполнять различные задания. Это могут быть ресурсы, достижения или что-то еще. Большинство заданий можно выполнить несколько раз, поэтому заходи сюда почаще и собирай награды, так ты сможешь быстрее развить Ковчег.",
    },
    notSubscribedText: {
      [LANGUAGES.eng]: "You are not subscribed to the channel.",
      [LANGUAGES.rus]: "Ты не подписан на канал.",
    },

    openText: {
      [LANGUAGES.eng]: "Open",
      [LANGUAGES.rus]: "Открыть",
    },
  },
  freinds: {
    friendsText: {
      [LANGUAGES.eng]: "FRIENDS",
      [LANGUAGES.rus]: "ДРУЗЬЯ",
    },
    collectText: {
      [LANGUAGES.eng]: "Collect",
      [LANGUAGES.rus]: "Собрать",
    },
    earnedForAllTimeText: {
      [LANGUAGES.eng]: "Earned for all time:",
      [LANGUAGES.rus]: "Заработано за все время:",
    },
    unconfirmedText: {
      [LANGUAGES.eng]: "Unconfirmed",
      [LANGUAGES.rus]: "Неподтвержденные",
    },
    confirmedText: {
      [LANGUAGES.eng]: "Confirmed",
      [LANGUAGES.rus]: "Подтвержденные",
    },
    advancedText: {
      [LANGUAGES.eng]: "Advanced",
      [LANGUAGES.rus]: "Продвинутые",
    },
    secondLevelRefText: {
      [LANGUAGES.eng]: "Second level referrals",
      [LANGUAGES.rus]: "Рефералы второго уровня",
    },
    copyLinkText: {
      [LANGUAGES.eng]: "Copy link",
      [LANGUAGES.rus]: "Копировать ссылку",
    },
    copiedText: {
      [LANGUAGES.eng]: "Copied",
      [LANGUAGES.rus]: "Скопировано",
    },
    inviteFriendText: {
      [LANGUAGES.eng]: "Invite a friend",
      [LANGUAGES.rus]: "Пригласить друга",
    },
    getNowText: {
      [LANGUAGES.eng]: "Get it now",
      [LANGUAGES.rus]: "Получай уже сейчас",
    },
    forRegistrationText: {
      [LANGUAGES.eng]: "for registration",
      [LANGUAGES.rus]: "за регистрацию",
    },
    fromEachCollectionText: {
      [LANGUAGES.eng]: "from each collection of referral resources",
      [LANGUAGES.rus]: "от каждого сбора ресурсов рефералов",
    },
    activityText: {
      [LANGUAGES.eng]:
        "Tokens are credited daily based on the activity of your referrals.",
      [LANGUAGES.rus]:
        "Ежедневное начисление жетонов зависит от активности ваших рефералов.",
    },
    lvlText: {
      [LANGUAGES.eng]: "lvl.",
      [LANGUAGES.rus]: "ур.",
    },
    noRewardText: {
      [LANGUAGES.eng]: "There is no reward for collection.",
      [LANGUAGES.rus]: "Нет вознаграждения для сбора.",
    },
    tutorial: {
      startText: {
        [LANGUAGES.eng]:
          "If you have many influential friends, you can invite them to build their Arks and revive humanity together. For each friend you will receive bonuses, but on the condition that he does not abandon his business.",
        [LANGUAGES.rus]:
          "Если у тебя есть множество влиятельных друзей, ты можешь позвать их строить свои Ковчеги и возродить человечество вместе. За каждого друга ты будешь получать постоянные бонусы, но при условии, что он не бросит свое дело.",
      },
      resetText: {
        [LANGUAGES.eng]:
          "For convenience, all your friends are divided: unconfirmed are those who never risked doing anything, confirmed are those who have collected resources at least once, and advanced are those who managed to reach the progress reset. We also track your friends' friends. You won't get many bonuses from them, but it's interesting to see how much your friends' network has grown.",
        [LANGUAGES.rus]:
          "Для удобства все твои друзья разделены, неподтвержденные – это кто так и не рискнул что-то сделать, подтвержденные – те, кто хотя бы раз собрал ресурсы, а продвинутые – те, кто смог дойти до сброса прогресса. Также мы отслеживаем и друзей твоих друзей. Много бонусов ты с них не получишь, зато интересно, насколько разрослась сеть твоих друзей.",
      },
      inviteText: {
        [LANGUAGES.eng]:
          "In order not to be distracted from important things, I have prepared your secret code for inviting friends in advance and written a welcome letter, you just need to click on the corresponding button.",
        [LANGUAGES.rus]:
          "Чтобы не отвлекаться от важных вещей я заранее подготовила твой секретный код для призыва друзей и написала приветственное письмо, тебе достаточно просто нажать на соответствующую кнопку.",
      },
    },
  },
  errorDesk: {
    gettingDataText: {
      [LANGUAGES.eng]:
        "An error occurred while fetching data. Please try again later or contact support if the issue persists.",
      [LANGUAGES.rus]:
        "Произошла ошибка при получении данных. Повторите попытку позже или обратитесь в службу поддержки, если проблема не устранена.",
    },
    addBlockedText: {
      [LANGUAGES.eng]:
        "To continue playing, please disable your ad blocker or purchase a premium account.",
      [LANGUAGES.rus]:
        "Для продолжения игры отключите, пожалуйста, блокировщик рекламы или купите премиум аккаунт",
    },
    longLoadingText: {
      [LANGUAGES.eng]:
        "The data is taking longer than expected to load. Please wait or refresh the page to try again.",
      [LANGUAGES.rus]:
        "Загрузка данных занимает больше времени, чем ожидалось. Пожалуйста, подождите или обновите страницу, чтобы повторить попытку.",
    },
    desktopAccessText: {
      [LANGUAGES.eng]:
        "Sorry, desktop access is temporarily unavailable. We'll bring this feature back later.",
      [LANGUAGES.rus]:
        "Извините, доступ с десктопа временно невозможен. Мы вернем данную возможность позже.",
    },
    telegramAccessText: {
      [LANGUAGES.eng]: "To access the game, you need to log in via Telegram.",
      [LANGUAGES.rus]:
        "Для доступа к игре необходимо авторизоваться через Telegram.",
    },

    refreshText: {
      [LANGUAGES.eng]: "Refresh",
      [LANGUAGES.rus]: "Обновить",
    },
  },
  wallet: {
    matterToTokenText: {
      [LANGUAGES.eng]: "Dark matter to token",
      [LANGUAGES.rus]: "Темная материя в жетоны",
    },
    matterText: {
      [LANGUAGES.eng]: "Dark matter",
      [LANGUAGES.rus]: "Темная материя",
    },
    tokenText: {
      [LANGUAGES.eng]: "Tokens",
      [LANGUAGES.rus]: "Жетоны",
    },
    setAllText: {
      [LANGUAGES.eng]: "Set all",
      [LANGUAGES.rus]: "Ставить все",
    },
    submitText: {
      [LANGUAGES.eng]: "Submit",
      [LANGUAGES.rus]: "Отправить",
    },
    notAvaiableText: {
      [LANGUAGES.eng]: `You don't have the "International Exchange" building.`,
      [LANGUAGES.rus]: `У тебя нет здания "Международная биржа".`,
    },
    trandferCompletedText: {
      [LANGUAGES.eng]: "Transfer completed successfully.",
      [LANGUAGES.rus]: "Перевод успешно завершён.",
    },
    trandferFailedText: {
      [LANGUAGES.eng]: "Transfer failed.",
      [LANGUAGES.rus]: "Перенос не выполнен.",
    },
  },
  hackingTerminal: {
    hackingTerminalText: {
      [LANGUAGES.eng]: "HACKING THE TERMINAL",
      [LANGUAGES.rus]: "ВЗЛОМ ТЕРМИНАЛА",
    },
    prizeListText: {
      [LANGUAGES.eng]: "Prize List",
      [LANGUAGES.rus]: "Список призов",
    },
    enterPasswordText: {
      [LANGUAGES.eng]: "Enter Password",
      [LANGUAGES.rus]: "Введите пароль",
    },
    checkButtonText: {
      [LANGUAGES.eng]: "Check",
      [LANGUAGES.rus]: "Проверить",
    },
    rulesOfTheGameText: {
      [LANGUAGES.eng]: "Rules of the Game",
      [LANGUAGES.rus]: "Правила игры",
    },
    attemptsRemainingText: {
      [LANGUAGES.eng]: "Attempts remaining",
      [LANGUAGES.rus]: "Осталось попыток",
    },
    historyOfAttemptsText: {
      [LANGUAGES.eng]: "History of attempts",
      [LANGUAGES.rus]: "История попыток",
    },
    whatYouCanWinText: {
      [LANGUAGES.eng]: "What You Can Win",
      [LANGUAGES.rus]: "Что можно выиграть?",
    },
    whenYouWinText: {
      [LANGUAGES.eng]:
        "When you win, you will receive one random bonus/reward from the list:",
      [LANGUAGES.rus]:
        "При выигрыше выдается один случайный бонус/награда из списка:",
    },

    resourcesFor15MinutesText: {
      [LANGUAGES.eng]: "Resources for 15 minutes",
      [LANGUAGES.rus]: "ресурсы за 15 минут",
    },
    resourcesFor30MinutesText: {
      [LANGUAGES.eng]: "Resources for 30 minutes",
      [LANGUAGES.rus]: "ресурсы за 30 минут",
    },
    resourcesFor45MinutesText: {
      [LANGUAGES.eng]: "Resources for 45 minutes",
      [LANGUAGES.rus]: "ресурсы за 45 минут",
    },
    freeBonusX2Text: {
      [LANGUAGES.eng]: "Ticket for any free bonus",
      [LANGUAGES.rus]: "Билет на любой бесплатный бонус",
    },
    TMText: {
      [LANGUAGES.eng]: "Dark matter",
      [LANGUAGES.rus]: "Темная материя",
    },
    tokenText: {
      [LANGUAGES.eng]: "Token",
      [LANGUAGES.rus]: "жетон",
    },
    yourTaskText: {
      [LANGUAGES.eng]: "Your task is to guess the 6-digit code",
      [LANGUAGES.rus]: "Ваша задача - угодать 6-значный код",
    },
    enter6DigitsText: {
      [LANGUAGES.eng]: "Enter 6 digits and click 'Check'",
      [LANGUAGES.rus]: "Введите 6 цифр и нажмите 'Проверить'",
    },
    afterCheckingText: {
      [LANGUAGES.eng]:
        "After checking, each digit will receive a color indication",
      [LANGUAGES.rus]: "После проверки каждая цифра получит цветовую индикацию",
    },
    greenDigitText: {
      [LANGUAGES.eng]: ["Green", " - the digit is in the correct place"],
      [LANGUAGES.rus]: ["Зеленый", " - цифра на правильном месте"],
    },
    yellowDigitText: {
      [LANGUAGES.eng]: [
        "Yellow",
        " - the digit is in the code, but not in its place",
      ],
      [LANGUAGES.rus]: ["Жёлтый", " - цифра есть в коде, но не на своём месте"],
    },
    redDigitText: {
      [LANGUAGES.eng]: ["Red", " - the digit is not in the code"],
      [LANGUAGES.rus]: ["Красный", " - цифры нет в коде"],
    },
    useHintsText: {
      [LANGUAGES.eng]: "Use the hints to find the code in 10 attempts",
      [LANGUAGES.rus]:
        "Используйте подсказки, чтобы подобрать код за 10 попыток",
    },
    ifYouGuessText: {
      [LANGUAGES.eng]: "If you guess, you will receive a random reward",
      [LANGUAGES.rus]: "Если угадали - получите случайную награду",
    },
    attemptsEndedText: {
      [LANGUAGES.eng]: "If the attempts are over, the hack failed, try again!",
      [LANGUAGES.rus]:
        "Если попытки закончились - взлом не удался, попробуйте снова!",
    },
    winText: {
      [LANGUAGES.eng]: "You win",
      [LANGUAGES.rus]: "Вы выиграли",
    },
    loseText: {
      [LANGUAGES.eng]: "You lose",
      [LANGUAGES.rus]: "Вы проиграли",
    },
    playAgainText: {
      [LANGUAGES.eng]: "Play again",
      [LANGUAGES.rus]: "Играть снова",
    },
    resourcesText: {
      [LANGUAGES.eng]: "Resources",
      [LANGUAGES.rus]: "Ресурсы",
    },
    matterText: {
      [LANGUAGES.eng]: "Dark matter",
      [LANGUAGES.rus]: "Темная материя",
    },
    freeBonusText: {
      [LANGUAGES.eng]: "Free bonus",
      [LANGUAGES.rus]: "Бесплатный бонус",
    },
    youReceivedText: {
      [LANGUAGES.eng]: "You Received",
      [LANGUAGES.rus]: "Вы получили",
    },
    errorText: {
      [LANGUAGES.eng]: "Something went wrong",
      [LANGUAGES.rus]: "Что-то пошло не так",
    },
  },
};
