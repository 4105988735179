import { useRef } from "react";
import { useEffect } from "react";

export const useOnClicka = (onReward, onError, id = 6056936) => {
  const showAd = useRef();

  useEffect(() => {
    window
      .initCdTma?.({ id })
      .then((show) => (showAd.current = show))
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShow = () => {
    showAd
      .current?.()
      .then(() => onReward?.())
      .catch((err) => onError?.(err));
  };

  return onShow;
};
