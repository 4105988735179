import React, { useEffect } from "react";
import "./ResourcesPopup.scss";
import Svg from "components/layout/Svg/Svg";
import {
  resourcesPopupBottomIcon,
  resourcesPopupLeftFigureIcon,
  resourcesPopupMoreIcon,
  resourcesPopupRightFigureIcon,
  resourcesPopupValueBgIcon,
} from "assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { statsImages } from "constants/stats";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import { useAdController } from "hooks/useAdController";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";
import { initOnClicka } from "utils/onClicka";
import Popup from "components/layout/Popup/Popup";
import MainBtn from "components/layout/MainBtn/MainBtn";
import { useTelegram } from "hooks/useTelegram";
import { getPlatformType } from "utils/getPlatformType";
import { useOnClicka } from "hooks/useOnClicka";
import { useAdstera } from "../../hooks/useAdstera";
import { useTooltip } from "hooks/usetooltip";
import Tooltip from "components/layout/Tooltip/Tooltip";
import { useSonarAd } from "hooks/useSonarAd";

const onClickSpotId = 6050153;

const {
  orText,
  viewAdText,
  availableForCollectionText,
  collectText,
  adsUnavailableText,
} = TRANSLATIONS.resourcePopup;

const ResourcesPopup = ({
  activePopup,
  onClosePopup,
  incomes,
  adIncomes,
  primaryBtnClass,
  title,
  primaryBtnTxt,
  primaryBtnStats,
  loading,
  onClickPrimaryBtn,
  adbtnClass,
  adBtnTxt,
  onClickAdBtn,
  showSonarAd,
  adByScript,
  adByClicko,
  blockId,
}) => {
  const tg = useTelegram();
  const location = useLocation();
  const navigate = useNavigate();
  const { show, openTooltip } = useTooltip();
  const onShowAdsgramAd = useAdController(blockId, onClickAdBtn);
  const onShowOnClickaAd = useOnClicka(onClickAdBtn, openTooltip);
  const onShowSonarAd = useSonarAd("bonuses",onClickAdBtn, openTooltip);

  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const isMobile = getPlatformType(tg);
  const { frameContainerRef, init: initAdstera } = useAdstera(
    "b8cf70b3ea2d5849d9d0a40c0c9341c7"
  );

  if (adByClicko === undefined) adByClicko = true;

  useEffect(() => {
    if (adByScript && activePopup) {
      initAdstera();
    }

    if (adByClicko) {
      initOnClicka(onClickSpotId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePopup]);

  const onClose = () => {
    onClosePopup();
    if (location.search.includes("popup=open")) {
      navigate(location.pathname);
    }
  };

  return (
    <>
      <Popup onClose={onClose} activePopup={activePopup}>
        <div className="resources-popup">
          <TransitionProvider
            style={TransitionStyleTypes.opacity}
            inProp={loading}
            className="resources-popup__loader"
          ></TransitionProvider>
          <h2 className="resources-popup__title">
            {title || availableForCollectionText[siteLanguage]}
          </h2>
          <div className="resources-popup__wrap f-jcsb">
            <div className="resources-popup__figure">
              <Svg id={resourcesPopupLeftFigureIcon} />
            </div>
            <div className="resources-popup__value f-center-center">
              {incomes.map(({ text, type }, index) => (
                <div
                  key={index}
                  className="resources-popup__value-content f-center"
                >
                  {type in statsImages ? (
                    <img src={statsImages[type]} alt="" />
                  ) : (
                    ""
                  )}
                  {text}
                </div>
              ))}
              <div className="resources-popup__value-bg">
                <Svg id={resourcesPopupValueBgIcon} />
              </div>
            </div>
            <div className="resources-popup__figure">
              <Svg id={resourcesPopupRightFigureIcon} />
            </div>
          </div>
          <div className="resources-popup__more">
            <Svg id={resourcesPopupMoreIcon} />
          </div>
          <div className="resources-popup__nav f-center-jcsb">
            <MainBtn
              asSecondary
              className={primaryBtnClass || ""}
              disabled={!onClickPrimaryBtn}
              onClick={() => {
                onClickPrimaryBtn();
              }}
            >
              {primaryBtnTxt || collectText[siteLanguage]}
              <div className="resources-popup__points">
                {primaryBtnStats &&
                  primaryBtnStats.map(({ text, type }, index) => (
                    <div
                      className="resources-popup__points-item f-center"
                      key={index}
                    >
                      {type in statsImages && (
                        <img src={statsImages[type]} alt="" />
                      )}
                      {text}
                    </div>
                  ))}
              </div>
            </MainBtn>
            <p className="resources-popup__and">{orText[siteLanguage]}</p>
            <MainBtn
              onClick={() => {
                isMobile
                  ? onShowAdsgramAd()
                  : showSonarAd
                  ? onShowSonarAd()
                  : onShowOnClickaAd();
              }}
              className={adbtnClass || ""}
            >
              {adBtnTxt || <>{viewAdText[siteLanguage]}</>}
              <div className="resources-popup__points">
                {adIncomes &&
                  adIncomes.map(({ text, type }, index) => (
                    <div
                      className="resources-popup__points-item f-center"
                      key={index}
                    >
                      {type in statsImages && (
                        <img src={statsImages[type]} alt="" />
                      )}
                      {text}
                    </div>
                  ))}
              </div>
            </MainBtn>
          </div>

          <div className="resources-popup__inner">
            <div className="resources-popup__banner">
              <div
                id="frame"
                ref={frameContainerRef}
                style={{ width: "300px", height: "auto" }}
              >
                {!adByScript && !adByClicko && (
                  <>
                    <iframe
                      title="banner"
                      data-aa="2369669"
                      src="//ad.a-ads.com/2369669?size=300x250"
                      style={{
                        width: "300px",
                        height: "250px",
                        border: "0px",
                        padding: "0",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                      }}
                    ></iframe>
                    <a
                      style={{
                        display: "block",
                        textAlign: "right",
                        fontSize: "12px",
                      }}
                      id="preview-link"
                      href="https://aads.com/campaigns/new/?source_id=2369669&source_type=ad_unit&partner=2369669"
                    >
                      Advertise here
                    </a>
                  </>
                )}
                {adByClicko && <div data-banner-id={onClickSpotId}></div>}
              </div>
            </div>
          </div>
          <div className="resources-popup__bottom">
            <Svg id={resourcesPopupBottomIcon} />
          </div>
        </div>
      </Popup>
      <Tooltip show={show} text={adsUnavailableText[siteLanguage]} />
    </>
  );
};

export default ResourcesPopup;
