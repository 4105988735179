export const useSonarAd = (adUnit, onReward, onError) => {
  return async () => {
    const res = await window.Sonar.show({
      adUnit: adUnit || "mini_games",
      onReward,
      onError,
    });

    console.log({ res });
  };
};
