import { statsDarkImg, statsTokenImg } from "assets/images";
import React, { useState } from "react";

import "./WalletForm.scss";
import Svg from "components/layout/Svg/Svg";
import { inputBgIcon, setAllBgIcon } from "assets/svg";
import MainBtn from "components/layout/MainBtn/MainBtn";
import { TRANSLATIONS } from "assets/translations";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/formatNumber";
import { changeMatterToToken } from "store/actions/profile";
import { useNavigate } from "react-router-dom";
import { useTooltip } from "hooks/usetooltip";
import { mainPagePath } from "router/path";
import Tooltip from "../../layout/Tooltip/Tooltip";

const {
  matterToTokenText,
  matterText,
  tokenText,
  setAllText,
  submitText,
  notAvaiableText,
  trandferCompletedText,
  trandferFailedText,
} = TRANSLATIONS.wallet;

const WalletForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const matterToTonRate = useSelector((state) => state.profile.matterToTonRate);
  const matter = useSelector((state) => state.profile.stats.matter);

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const { openTooltip, show } = useTooltip();

  const resultTokens = +value && +value / matterToTonRate;

  const isValueInvalid = +value > matter || value < 0;

  const onSubmit = async () => {
    try {
      setLoading(true);
      await dispatch(changeMatterToToken(value));
      setTooltipText(trandferCompletedText[siteLanguage]);
      openTooltip();

      setTimeout(() => {
        navigate(mainPagePath);
      }, 2000);
    } catch (error) {
      setTooltipText(trandferFailedText[siteLanguage]);
      openTooltip();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="wallet-form">
        <h3 className="wallet-form__title">
          {!matterToTonRate
            ? notAvaiableText[siteLanguage]
            : matterToTokenText[siteLanguage]}
        </h3>
        {!!matterToTonRate && (
          <>
            <div className="wallet-form__cource">
              <span className="wallet-form__cource-text">{1}</span>
              <img
                src={statsTokenImg}
                alt="Dark Matter"
                className="wallet-form__cource-img"
              />
              <span className="wallet-form__cource-text">
                = {matterToTonRate}
              </span>
              <img
                src={statsDarkImg}
                alt="Token"
                className="wallet-form__cource-img"
              />
            </div>
            <label className="wallet-form__input-wrapper">
              <input
                type="number"
                value={value && +(+value).toFixed(5)}
                disabled={loading}
                onChange={(e) => setValue(e.target.value)}
                placeholder={`0 ${matterText[siteLanguage]}`}
                className={`wallet-form__input ${
                  isValueInvalid ? "invalid" : ""
                }`}
              />
              <Svg id={inputBgIcon} className="wallet-form__input-bg" />
            </label>
            <button
              onClick={() => setValue(matter)}
              className="wallet-form__see-all-btn"
            >
              <span>
                {setAllText[siteLanguage]} ({formatNumber(matter)})
              </span>
              <Svg id={setAllBgIcon} className="wallet-form__see-all-btn-bg" />
            </button>
            <label className="wallet-form__input-wrapper">
              <input
                value={`${+resultTokens.toFixed(3)} ${tokenText[siteLanguage]}`}
                placeholder={`0 ${tokenText[siteLanguage]}`}
                type="text"
                className="wallet-form__input"
                disabled
              />
              <Svg id={inputBgIcon} className="wallet-form__input-bg" />
            </label>
            <MainBtn
              onClick={onSubmit}
              disabled={!+value || isValueInvalid || loading}
              className="wallet-form__submit-btn"
            >
              {submitText[siteLanguage]}
            </MainBtn>
          </>
        )}
      </div>
      <Tooltip show={show} text={tooltipText} />
    </div>
  );
};

export default WalletForm;
