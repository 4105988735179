import { updateUserDetails } from "store/slices/profileSlice";
import { fetchRequest } from "store/tools/fetchTools";

const getHackterminalCodeUrl = "terminal_hacking/generate_random_number/";
const getHackterminalReward = "terminal_hacking/generate_event/";

export const fetchHacktermnialCode = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      `${getHackterminalCodeUrl}`,
      tgId,
      siteLanguage
    );
    return data.random_number;
  } catch (error) {
    throw error;
  }
};

export const fetchHacktermnialReward = () => async (dispatch, getState) => {
  const tgId = getState().profile.tgData.tgId;
  const siteLanguage = getState().ui.siteLanguage;

  try {
    const data = await fetchRequest(
      `${getHackterminalReward}`,
      tgId,
      siteLanguage
    );

    const {
      time_after_harvest,
      quantity_harvest,
      time_after_intermediate_harvest,
      timer_speed_bonus,
      summary_income,
      max_time_harvest,
      step_permanent_bonus,
      date_permanent_bonus,
      value_permanent_bonus,
      value_resources_bonus,
      resources,
      matter,
      tokens,
    } = data.account;

    dispatch(
      updateUserDetails({
        stats: {
          resources,
          matter,
          tokens,
        },
        time_after_harvest,
        quantity_harvest,
        time_after_intermediate_harvest,
        timer_speed_bonus,
        step_permanent_bonus,
        date_permanent_bonus,
        value_permanent_bonus,
        value_resources_bonus,
        summary_income,
        max_time_harvest,
      })
    );

    return {
      bonus_type: data.bonus_type,
      bonus_value: data.bonus_value,
    };
  } catch (error) {
    throw error;
  }
};
