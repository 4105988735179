import React, { useState } from "react";
import "./Header.scss";

import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { statsDarkImg, statsResImg, statsTokenImg } from "assets/images";
import {
  hackTerminalPagePath,
  mainPagePath,
  walletPagePath,
} from "router/path";
import {
  avatarBgIcon,
  backIcon,
  headerBgIcon,
  resourcesIcon,
  settingsIcon,
  userIcon,
} from "assets/svg";
import Svg from "../layout/Svg/Svg";
import { formatNumber } from "utils/formatNumber";
import SettingsPopup from "components/SettingsPopup/SettingsPopup";
import { TRANSLATIONS } from "assets/translations";
import { useTooltip } from "hooks/usetooltip";
import Tooltip from "components/layout/Tooltip/Tooltip";

const inDevelopmentText = TRANSLATIONS.inDevelopmentText;

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const avatar = useSelector((state) => state.profile.tgData.avatar);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const { show, openTooltip } = useTooltip();

  const resources = useSelector((state) => state.profile.stats.resources);
  const matter = useSelector((state) => state.profile.stats.matter);
  const tokens = useSelector((state) => state.profile.stats.tokens);

  const showLocation = location.pathname !== mainPagePath;
  const isHidden = location.pathname === hackTerminalPagePath;

  const isInWalletPage = location.pathname === walletPagePath;

  if (isHidden) return null;
  return (
    <header className="header">
      <div className="container">
        <div className="header-wrapper f-center-jcsb">
          <button
            onClick={!isInWalletPage ? openTooltip : () => navigate(-1)}
            className="header-link f-center-center"
          >
            <Svg id={isInWalletPage ? backIcon : userIcon} />
          </button>
          <button onClick={openTooltip} className="header-user">
            <div className="header-user__avatar">
              {avatar && <img className="cover-image" src={avatar} alt="" />}
            </div>
            <div className="header-user__bg">
              <Svg id={avatarBgIcon} />
            </div>
          </button>
          <button
            onClick={() => setSettingsOpened(true)}
            className="header-link f-center-center"
          >
            <Svg id={settingsIcon} />
          </button>
          <div className="header-wrapper__bg">
            <Svg id={headerBgIcon} />
          </div>
        </div>
        {showLocation && (
          <div
            className="header-resources"
            onClick={() => navigate(walletPagePath)}
          >
            <ul className="header-resources-list f-center-center">
              <li className="header-resources-list__item f-center">
                <div className="header-resources-list__icon">
                  <img className="cover-image" src={statsResImg} alt="" />
                </div>
                <p className="header-resources-list__text">
                  {formatNumber(resources)}
                </p>
              </li>
              <li className="header-resources-list__item f-center">
                <div className="header-resources-list__icon">
                  <img className="cover-image" src={statsDarkImg} alt="" />
                </div>
                <p className="header-resources-list__text">
                  {(+matter).toFixed(3)}
                </p>
              </li>
              <li className="header-resources-list__item f-center">
                <div className="header-resources-list__icon">
                  <img className="cover-image" src={statsTokenImg} alt="" />
                </div>
                <p className="header-resources-list__text">
                  {(+tokens).toFixed(3)}
                </p>
              </li>
            </ul>
            <div className="header-resources__bg">
              <Svg id={resourcesIcon} />
            </div>
          </div>
        )}
      </div>
      <SettingsPopup
        activePopup={settingsOpened}
        onClose={() => setSettingsOpened(false)}
      />
      <Tooltip show={show} text={inDevelopmentText[siteLanguage]} />
    </header>
  );
};

export default Header;
