import React, { useEffect, useState } from "react";
import ResourcesPopup from "components/ResourcesPopup/ResourcesPopup";
import "./MainMineBlock.scss";
import { useLocation } from "react-router-dom";
import Svg from "components/layout/Svg/Svg";
import {
  mainCollectBgIcon,
  mainMineBlockLeftTimeBgIcon,
  mainMineBlockParamsBgIcon,
  mainMineBlockRightTimeBlockIcon,
  permanentBonusBgIcon,
  resetProgressBgIcon,
  resouceBonusBgIcon,
  speedBonusBgIcon,
} from "assets/svg";
import { coinTypes } from "constants/coinTypes";
import { adsgramBlocks } from "constants/adsgram";
import { useAdController } from "../../../hooks/useAdController";
import { useDispatch, useSelector } from "react-redux";
import { formatMilliseconds } from "utils/formatMilliseconds";
import { formatNumber } from "utils/formatNumber";
import { getBonus, getBonusTypes, harvest } from "store/actions/profile";
import Tooltip from "components/layout/Tooltip/Tooltip";
import { useTooltip } from "hooks/usetooltip";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { TRANSLATIONS } from "assets/translations";
import ResetModal from "../ResetModal/ResetModal";
import { useOnClicka } from "hooks/useOnClicka";
import { useTelegram } from "hooks/useTelegram";
import { getPlatformType } from "utils/getPlatformType";

const {
  extractiontimeText,
  resourcesMinedText,
  collectText,
  permanentBonusText,
  currentBonusText,
  resourceBonusText,
  conditionsNotMetText,
  closeText,
} = TRANSLATIONS.home;
const { adsUnavailableText } = TRANSLATIONS.resourcePopup;

const NavBtn = ({
  icon,
  incomes,
  adIncomes,
  blockId,
  primaryBtnTxt,
  primaryBtnDisabled,
  bonusType,
  title,
  disabled,
  onClickBtn,
  usePrimaryBtnAsClose,
  siteLanguage,
  showSonarAd
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [activePopup, setActivePopup] = useState(false);

  const onClick = async (isWithAd) => {
    if (usePrimaryBtnAsClose && !isWithAd) {
      setActivePopup(false);
      return;
    }
    if (!bonusType) return;
    setLoading(true);
    await dispatch(getBonus(bonusType, isWithAd));
    setLoading(false);
    setActivePopup(false);
  };

  return (
    <>
      <button
        disabled={disabled}
        className="main-mine-block__nav"
        onClick={() => {
          onClickBtn ? onClickBtn() : setActivePopup(true);
        }}
      >
        <div className="main-mine-block__nav-btn">
          <Svg id={icon[siteLanguage]} />
        </div>
      </button>
      <ResourcesPopup
        activePopup={activePopup}
        onClosePopup={() => setActivePopup(false)}
        incomes={incomes}
        title={title}
        adIncomes={adIncomes}
        primaryBtnTxt={primaryBtnTxt}
        onClickPrimaryBtn={!primaryBtnDisabled ? () => onClick() : null}
        onClickAdBtn={() => onClick(true)}
        blockId={blockId}
        loading={loading}
        showSonarAd={showSonarAd}
      />
    </>
  );
};

const CollectBtn = ({ quantity_harvest, openTooltip }) => {
  const dispatch = useDispatch();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const [activePopup, setActivePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes("popup=open")) {
      setActivePopup(true);
    }
  }, [location]);

  const onCollect = async (useAd) => {
    try {
      setLoading(true);
      await dispatch(harvest(useAd));

      setActivePopup(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="main-mine-block__btn"
        onClick={() =>
          quantity_harvest ? setActivePopup(true) : openTooltip()
        }
      >
        <span className="main-mine-block__btn-text">
          {collectText[siteLanguage]}
        </span>
        <div className="main-mine-block__btn-bg">
          <Svg id={mainCollectBgIcon} />
        </div>
      </div>
      <ResourcesPopup
        loading={loading}
        activePopup={activePopup}
        onClosePopup={() => setActivePopup(false)}
        incomes={[
          { text: formatNumber(quantity_harvest), type: coinTypes.resources },
        ]}
        adIncomes={[{ text: "x2", type: coinTypes.resources }]}
        blockId={adsgramBlocks.resourceHarvest}
        onClickPrimaryBtn={onCollect}
        onClickAdBtn={() => onCollect(true)}
      />
    </>
  );
};

const MainMineBlock = () => {
  const tg = useTelegram();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const hapticFeedback = useHapticFeedback();
  const notificationOccurred = hapticFeedback[1];
  const dispatch = useDispatch();
  const step_permanent_bonus = useSelector(
    (state) => state.profile.step_permanent_bonus
  );
  const value_permanent_bonus = useSelector(
    (state) => state.profile.value_permanent_bonus
  );
  const time_after_harvest = useSelector(
    (state) => state.profile.time_after_harvest
  );
  const value_resources_bonus = useSelector(
    (state) => state.profile.value_resources_bonus
  );
  const quantity_harvest = useSelector(
    (state) => state.profile.quantity_harvest
  );
  const date_permanent_bonus = useSelector(
    (state) => state.profile.date_permanent_bonus
  );
  const timer_speed_bonus = useSelector(
    (state) => state.profile.timer_speed_bonus
  );
  const resetEnable = useSelector((state) => state.profile.resetEnable);
  const { openTooltip, show } = useTooltip();
  const [tooltipText, setTooltipText] = useState(conditionsNotMetText);

  const [resetModalOpened, setResetModalOpened] = useState(false);

  const getSpeedBonus = () => {
    dispatch(getBonus(getBonusTypes.speed, true));
  };
  const onShowAdsgramAd = useAdController(
    adsgramBlocks.miningSpeedBonus,
    getSpeedBonus
  );
  const onShowOnClickaAd = useOnClicka(getSpeedBonus, () => {
    setTooltipText(adsUnavailableText);
    openTooltip();
  });

  const isMobile = getPlatformType(tg);

  const onReset = () => {
    if (!resetEnable) {
      setTooltipText(conditionsNotMetText);
      openTooltip();
      notificationOccurred("success");
    } else {
      setResetModalOpened(true);
    }
  };

  return (
    <>
      <div className="main-mine-block">
        <div className="main-mine-block__params">
          <div className="main-mine-block__params-head f-center-jcsb">
            <p className="main-mine-block__params-point">
              {extractiontimeText[siteLanguage]}
            </p>
            <p className="main-mine-block__params-point">
              {resourcesMinedText[siteLanguage]}
            </p>
          </div>
          <div className="main-mine-block__params-wrap f-center-jcsb">
            <p className="main-mine-block__params-value main-mine-block__params-value_time f-center-center">
              {formatMilliseconds(time_after_harvest)}
            </p>
            <p className="main-mine-block__params-value f-center-center">
              {formatNumber(quantity_harvest)}
            </p>
          </div>
          <div className="main-mine-block__params-bg">
            <Svg id={mainMineBlockParamsBgIcon} />
          </div>
        </div>
        <div className="main-mine-block__control">
          <div className="main-mine-block__time f-jcsb">
            <div
              className={`main-mine-block__time-item ${
                !date_permanent_bonus ? "hideen" : ""
              }`}
            >
              <span className="main-mine-block__time-value">
                {formatMilliseconds(date_permanent_bonus)}
              </span>
              <div className="main-mine-block__time-bg">
                <Svg id={mainMineBlockLeftTimeBgIcon} />
              </div>
            </div>
            <div
              className={`main-mine-block__time-item ${
                !timer_speed_bonus ? "hideen" : ""
              }`}
            >
              <span className="main-mine-block__time-value">
                {formatMilliseconds(timer_speed_bonus)}
              </span>
              <div className="main-mine-block__time-bg">
                <Svg id={mainMineBlockRightTimeBlockIcon} />
              </div>
            </div>
          </div>
          <div className="main-mine-block__control-inner ">
            <NavBtn
              siteLanguage={siteLanguage}
              disabled={date_permanent_bonus}
              icon={permanentBonusBgIcon}
              title={permanentBonusText[siteLanguage]}
              incomes={[{ text: `+${formatNumber(step_permanent_bonus, 3)}%` }]}
              primaryBtnTxt={`${currentBonusText[siteLanguage]} ${formatNumber(
                value_permanent_bonus,
                3
              )}`}
              blockId={adsgramBlocks.permanentBonus}
              bonusType={getBonusTypes.permanent}
              primaryBtnDisabled={true}
            />
            <div
              disabled={timer_speed_bonus >= 86400}
              className="main-mine-block__nav"
              onClick={isMobile ? onShowAdsgramAd : onShowOnClickaAd}
            >
              <div className="main-mine-block__nav-btn">
                <Svg id={speedBonusBgIcon[siteLanguage]} />
              </div>
            </div>
            <NavBtn
              siteLanguage={siteLanguage}
              icon={resouceBonusBgIcon}
              incomes={[
                {
                  text: `${formatNumber(value_resources_bonus)}`,
                  type: coinTypes.resources,
                },
              ]}
              showSonarAd
              primaryBtnTxt={closeText[siteLanguage]}
              usePrimaryBtnAsClose={true}
              title={resourceBonusText[siteLanguage]}
              bonusType={getBonusTypes.resources}
              blockId={adsgramBlocks.resourcesBonus}
            />

            <div
              disabled={timer_speed_bonus >= 86400}
              className="main-mine-block__nav"
              onClick={onReset}
            >
              <div className="main-mine-block__nav-btn">
                <Svg id={resetProgressBgIcon[siteLanguage]} />
              </div>
            </div>
            <CollectBtn
              quantity_harvest={quantity_harvest}
              openTooltip={openTooltip}
            />
          </div>
        </div>
      </div>
      <Tooltip show={show} text={tooltipText[siteLanguage]} />
      <ResetModal
        activePopup={resetModalOpened}
        onClose={() => setResetModalOpened(false)}
      />
    </>
  );
};

export default MainMineBlock;
