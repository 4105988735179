import React, { useEffect, useRef } from "react";
import "./TasksList.scss";
import { statsImages } from "constants/stats";
import Svg from "components/layout/Svg/Svg";
import { resIconImg } from "assets/images";
import {
  taskDailyBgIcon,
  taskGetBtnBgIcon,
  taskListRewardBgIcon,
  taskSubscribeBtnBgIcon,
} from "../../../assets/svg";
import { TRANSLATIONS } from "assets/translations";
import { useDispatch, useSelector } from "react-redux";
import { initOnClicka } from "utils/onClicka";
import { formatMilliseconds } from "utils/formatMilliseconds";
import { formatNumber } from "utils/formatNumber";
import {
  fetchDailyCollect,
  rewardTraffyTask,
  subscribeClickLinkBot,
  subscribePartnersTask,
  subscribeTask,
} from "store/actions/profile";
import { useTelegram } from "hooks/useTelegram";
import Tooltip from "components/layout/Tooltip/Tooltip";
import { useTooltip } from "hooks/usetooltip";
import {
  increaseSubscribeTimers,
  updateSubscribeTask,
} from "store/slices/profileSlice";
import { useTimer } from "hooks/useTimer";
import { useNavigate } from "react-router-dom";
import { hackTerminalPagePath } from "router/path";

const {
  dailyLoginRewardText,
  dayText,
  collectText,
  subscribeText,
  getText,
  lvlText,
  notSubscribedText,
  spenderText,
  checkText,
  receivedText,
  openText,
  hackingTerminalText,
  supportProjectText,
} = TRANSLATIONS.tasks;
const onClickSpotId = 6050154;

const AdBanner = ({ type, id }) => {
  useEffect(() => {
    if (type === "onclicka") {
      initOnClicka(6052313);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (type === "onclicka") {
    return (
      <div className="tasks-list__banner">
        <div data-banner-id={6052313}></div>
      </div>
    );
  }

  return null;
};

const RegularTaskItem = ({
  name,
  keyName,
  description,
  bonus,
  link_name,
  link,
  is_subscribed,
  time_left,
  siteLanguage,
  bonus_type,
  onSubscribe,
}) => {
  const tg = useTelegram();
  const dispatch = useDispatch();

  const isClickBot = link_name === "False";
  return (
    <div className="tasks-list__card">
      <div className="tasks-list__main">
        <h3 className="tasks-list__title">{name}</h3>
        <p className="tasks-list__text">{description}</p>
        <div className="tasks-list__offer f-center-jcsb">
          <div className="tasks-list__reward f-center-center">
            <img src={statsImages[bonus_type || "resources"]} alt="" />
            {formatNumber(bonus)}
            <div className="tasks-list__reward-bg">
              <Svg id={taskListRewardBgIcon} />
            </div>
          </div>
          <div className="tasks-list__btns f-center">
            {time_left ? (
              <p className="tasks-list__item-timer">
                {formatMilliseconds(time_left)}
              </p>
            ) : (
              <>
                {!is_subscribed && (
                  <button
                    onClick={() => {
                      if (isClickBot) {
                        dispatch(subscribeClickLinkBot(link, keyName));
                      }

                      if (process.env.NODE_ENV !== "development") {
                        tg.openTelegramLink(
                          link || `https://t.me/${name.slice(1)}`
                        );
                      }
                    }}
                    type="button"
                    className="tasks-list__btn"
                  >
                    {subscribeText[siteLanguage]}
                    <div className="tasks-list__btn-bg">
                      <Svg id={taskSubscribeBtnBgIcon} />
                    </div>
                  </button>
                )}
                {((isClickBot && is_subscribed) || !isClickBot) && (
                  <button
                    onClick={() => {
                      if (keyName) {
                        onSubscribe(keyName, link);
                      } else onSubscribe(name);
                    }}
                    type="button"
                    className="tasks-list__btn"
                  >
                    {getText[siteLanguage]}
                    <div className="tasks-list__btn-bg">
                      <Svg id={taskGetBtnBgIcon} />
                    </div>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SpecialTaskItem = ({ name, siteLanguage }) => {
  const navigate = useNavigate();

  return (
    <div className="tasks-list__card">
      <div className="tasks-list__main f-center-center">
        <h3 className="tasks-list__title">{name}</h3>
        <div className="tasks-list__offer f-end">
          <div className="tasks-list__btns f-center">
            <button
              onClick={() => navigate(hackTerminalPagePath)}
              type="button"
              className="tasks-list__btn"
            >
              {openText[siteLanguage]}
              <div className="tasks-list__btn-bg">
                <Svg id={taskSubscribeBtnBgIcon} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdvancedTaskItem = ({
  title,
  description,
  level,
  progress,
  siteLanguage,
}) => (
  <div className="tasks-list__card">
    <div className="tasks-list__main">
      <div className="tasks-list__head f-center-jcsb">
        <h3 className="tasks-list__title">{title}</h3>
        <p className="tasks-list__lvl">
          <span>{level}</span> {lvlText[siteLanguage]}
        </p>
      </div>
      <p className="tasks-list__text">{description}</p>
      <div className="tasks-list__offer f-center-jcsb">
        <div className="tasks-list__tag">
          {spenderText[siteLanguage]}
          <div className="tasks-list__tag-bg">
            <Svg id={taskListRewardBgIcon} />
          </div>
        </div>
        <div className="tasks-list__btns f-center">
          <p className="tasks-list__count">
            {progress[0]} <span>/ {progress[10]}</span>
          </p>
          <button type="button" className="tasks-list__btn">
            {getText[siteLanguage]}
            <div className="tasks-list__btn-bg">
              <Svg id={taskGetBtnBgIcon} />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
);

const TasksList = ({ activeTab }) => {
  const dispatch = useDispatch();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const subscribe = useSelector((state) => state.profile.subscribe);
  // const adsgram_bonus_value = useSelector(
  //   (state) => state.profile.adsgram_bonus_value
  // );
  const subscribe_partners = useSelector(
    (state) => state.profile.subscribe_partners
  );
  const day = useSelector((state) => state.profile.dailylogin.day);
  const enable = useSelector((state) => state.profile.dailylogin.enable);
  const end_of_day = useSelector(
    (state) => state.profile.dailylogin.end_of_day
  );
  const total_bonus = useSelector(
    (state) => state.profile.dailylogin.total_bonus
  );

  const traffyTasksRef = useRef(null);
  const taskRef = useRef(null);

  const subscribeTimer = [
    ...Object.values(subscribe),
    ...Object.values(subscribe_partners),
  ].find((item) => item?.time_left);

  useTimer(subscribeTimer?.time_left || 0, increaseSubscribeTimers);

  const { openTooltip, show } = useTooltip();

  const subscribeTasks = Object.keys(subscribe).map((key) => {
    return {
      name: key,
      ...subscribe[key],
    };
  });
  const subscribePartnersTasks = Object.keys(subscribe_partners).map((key) => ({
    keyName: key,
    ...subscribe_partners[key],
  }));

  const tasks = {
    regular: [...subscribeTasks, ...subscribePartnersTasks],
    advanced: [],
    special: [
      {
        name: hackingTerminalText[siteLanguage],
      },
    ],
  };

  useEffect(() => {
    initOnClicka(onClickSpotId);
  }, []);

  useEffect(() => {
    if (traffyTasksRef.current) {
      const onTaskLoad = () => {};
      const onTaskRender = (
        changeReward,
        changeCardTitle,
        changeDescription,
        changeButtonCheckText
      ) => {
        changeReward("0.01");
        changeDescription("200K");
        changeButtonCheckText(checkText[siteLanguage]);
      };
      const onTaskReward = () => {
        dispatch(rewardTraffyTask());
      };
      const onTaskReject = openTooltip;
      window.Traffy.renderTasks(traffyTasksRef.current, {
        max_tasks: 1,
        onTaskLoad,
        onTaskRender,
        onTaskReward,
        onTaskReject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traffyTasksRef]);

  // useEffect(() => {
  //   const handler = (event) => {
  //     console.log({ event });
  //     // event.detail contains your block id
  //   };
  //   const task = taskRef.current;

  //   if (task) {
  //     task.addEventListener("reward", handler);
  //   }

  //   return () => {
  //     if (task) {
  //       task.removeEventListener("reward", handler);
  //     }
  //   };
  // }, []);

  const onSubscribe = async (name, link) => {
    try {
      let res = {};
      if (link) {
        res = await dispatch(subscribePartnersTask(link, name));
      } else {
        res = await dispatch(subscribeTask(name));
      }

      if (res?.status === 400) {
        openTooltip();
        if (subscribe[name].is_subscribed) {
          dispatch(
            updateSubscribeTask({
              key: name,
              data: { ...subscribe[name], is_subscribed: false },
            })
          );
        }
      }
    } catch (error) {}
  };

  return (
    <div className="tasks-list">
      <div className={`tasks-list__content-item`}>
        <ul>
          {!activeTab && (
            <>
              <li className="tasks-list__item">
                <div className="tasks-list__card">
                  <div className="tasks-list__daily f-center-jcsb">
                    <h3 className="tasks-list__title">
                      {dailyLoginRewardText[siteLanguage]}
                    </h3>
                    <div className="tasks-list__daily-date">
                      <p className="tasks-list__daily-title">
                        {dayText[siteLanguage]}
                      </p>
                      <p className="tasks-list__daily-value">{day}</p>
                      <div className="tasks-list__daily-bg">
                        <Svg id={taskDailyBgIcon} />
                      </div>
                    </div>
                    <div className="tasks-list__daily-info">
                      <div className="tasks-list__reward f-center-center">
                        <img src={resIconImg} alt="" />
                        {formatNumber(total_bonus)}
                        <div className="tasks-list__reward-bg">
                          <Svg id={taskListRewardBgIcon} />
                        </div>
                      </div>
                      {end_of_day ? (
                        <p className="tasks-list__timer">
                          {formatMilliseconds(end_of_day)}
                        </p>
                      ) : (
                        <button
                          onClick={() => dispatch(fetchDailyCollect())}
                          disabled={!enable}
                          type="button"
                          className="tasks-list__btn"
                        >
                          {collectText[siteLanguage]}
                          <div className="tasks-list__btn-bg">
                            <Svg id={taskGetBtnBgIcon} />
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              <li className="tasks-list__item">
                <div className="tasks-list__banner">
                  <div data-banner-id={onClickSpotId}></div>
                </div>
              </li>
            </>
          )}
          {Object.values(tasks)[activeTab].map((task, index) => {
            let content = "";

            switch (activeTab) {
              case 0: {
                if (task?.keyName?.startsWith("ad")) {
                  content = <AdBanner {...task[0]} />;
                } else {
                  content = (
                    <RegularTaskItem
                      {...task}
                      siteLanguage={siteLanguage}
                      onSubscribe={onSubscribe}
                    />
                  );
                }
                break;
              }
              case 1: {
                content = (
                  <AdvancedTaskItem {...task} siteLanguage={siteLanguage} />
                );
                break;
              }
              default:
                content = (
                  <SpecialTaskItem {...task} siteLanguage={siteLanguage} />
                );
            }

            return (
              <li className="tasks-list__item" key={index}>
                {content}
              </li>
            );
          })}
        </ul>
        <div
          style={{ display: activeTab ? "none" : "flex" }}
          className="tasks-list__traffy-container"
          ref={traffyTasksRef}
        ></div>
        <div
          style={{ display: !activeTab ? undefined : "none" }}
          className="tasks-list__card tasks-list__main"
        >
          <adsgram-task
            data-block-id="task-7805"
            data-debug="false"
            className="tasks-list__adsgram-card"
            ref={taskRef}
          >
            <div className="tasks-list__adsgram-income" slot="reward">
              {supportProjectText[siteLanguage]}
            </div>
            <div slot="button">
              <button type="button" className="tasks-list__btn">
                {getText[siteLanguage]}
                <div className="tasks-list__btn-bg">
                  <Svg id={taskGetBtnBgIcon} />
                </div>
              </button>
            </div>
            <div slot="done">
              <button type="button" className="tasks-list__btn">
                {receivedText[siteLanguage]}
                <div className="tasks-list__btn-bg">
                  <Svg id={taskGetBtnBgIcon} />
                </div>
              </button>
            </div>
          </adsgram-task>
        </div>
      </div>
      <Tooltip show={show} text={notSubscribedText[siteLanguage]} />
    </div>
  );
};

export default TasksList;
