import React, { useEffect, useState } from "react";

import {
  numpadArrowRightIcon,
  numpadArrowLeftIcon,
  historyToggleIcon,
  hackterminalCloseIcon,
  hackTerminalBackIcon,
} from "../../../assets/svg";
import Svg from "components/layout/Svg/Svg";

import "./HackTerminalMain.scss";
import { initOnClicka } from "utils/onClicka";
// import { useAdstera } from "../../../hooks/useAdstera";
import { useNavigate } from "react-router-dom";
import { tasksPagePath } from "router/path";
import { TRANSLATIONS } from "assets/translations";
import { useDispatch, useSelector } from "react-redux";
import HackTerminalModal from "./HackTerminalModal/HackTerminalModal";
import {
  fetchHacktermnialCode,
  fetchHacktermnialReward,
} from "store/actions/hackTerminal";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import { showInterstetialAdd } from "utils/showInterstetialAdd";
import { getPlatformType } from "utils/getPlatformType";
import { useTelegram } from "hooks/useTelegram";
import { useSonarAd } from "hooks/useSonarAd";

const {
  hackingTerminalText,
  prizeListText,
  enterPasswordText,
  checkButtonText,
  rulesOfTheGameText,
  whatYouCanWinText,
  resourcesFor15MinutesText,
  resourcesFor30MinutesText,
  resourcesFor45MinutesText,
  freeBonusX2Text,
  TMText,
  tokenText,
  yourTaskText,
  enter6DigitsText,
  afterCheckingText,
  greenDigitText,
  yellowDigitText,
  redDigitText,
  useHintsText,
  ifYouGuessText,
  attemptsEndedText,
  historyOfAttemptsText,
  whenYouWinText,
  attemptsRemainingText,
  errorText,
} = TRANSLATIONS.hackingTerminal;

const colors = {
  yellow: "#eeff00",
  red: "#e20000",
  blue: "#00ff88",
};

const onClickSpotId = 6056755;

const MainTab = ({
  writedCode,
  generatedCode,
  attempts,
  onChangeCode,
  onCheck,
  history,
  errored,
}) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const [historyOpened, setHistoryOpened] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const onClickArrow = (isBack) => {
    const nextIndex = activeIndex + (isBack ? -1 : 1);
    if (nextIndex > writedCode.length - 1 || nextIndex < 0) return;

    setActiveIndex(nextIndex);
  };

  const onClickNum = (value) => {
    onChangeCode(value, activeIndex);
    if (activeIndex < writedCode.length - 1)
      setActiveIndex((prevState) => prevState + 1);
  };

  const getSymbolColor = (value, index, code) => {
    if (generatedCode.includes(value)) {
      if (generatedCode[index] === code[index]) {
        return colors.blue;
      } else {
        return colors.yellow;
      }
    } else {
      return colors.red;
    }
  };

  if (errored) {
    return (
      <h6 className="hack-terminal-main__error-text">
        {errorText[siteLanguage]}
      </h6>
    );
  }

  return (
    <>
      <h6 className="hack-terminal-main__atempts-text">
        {attemptsRemainingText[siteLanguage]}: {attempts}
      </h6>
      <div className="hack-terminal-main__password-wrapper">
        {writedCode.split("").map((item, index) => (
          <button
            onClick={() => setActiveIndex(index)}
            key={index}
            className={`hack-terminal-main__password-num-btn ${
              activeIndex === index ? "active" : ""
            }`}
          >
            {item}
          </button>
        ))}
      </div>

      <div className="hack-terminal-main__history">
        <h6 className="hack-terminal-main__history-text">
          {historyOfAttemptsText[siteLanguage]}
        </h6>
        <div
          className={`hack-terminal-main__history-list ${
            historyOpened ? "open" : ""
          }`}
        >
          {history.map((code) => (
            <p className="hack-terminal-main__history-list-item">
              {code.split("").map((item, index) => (
                <span style={{ color: getSymbolColor(item, index, code) }}>
                  {item}
                </span>
              ))}
            </p>
          ))}
        </div>
        <button
          className={`hack-terminal-main__toggle-list-btn ${
            historyOpened ? "open" : ""
          }`}
          onClick={() => setHistoryOpened((prevState) => !prevState)}
        >
          <Svg id={historyToggleIcon} />
        </button>
        <div
          className={`hack-terminal-main__numpad ${
            historyOpened ? "closed" : ""
          }`}
        >
          {Array.from({ length: 9 }).map((_, index) => (
            <button
              onClick={() => onClickNum(index + 1)}
              key={index}
              className="hack-terminal-main__numpad-btn"
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => onClickArrow(true)}
            className="hack-terminal-main__numpad-btn"
          >
            <Svg id={numpadArrowLeftIcon} />
          </button>
          <button
            className="hack-terminal-main__numpad-btn"
            onClick={() => onClickNum("0")}
          >
            0
          </button>
          <button
            onClick={() => onClickArrow()}
            className="hack-terminal-main__numpad-btn"
          >
            <Svg id={numpadArrowRightIcon} />
          </button>
        </div>
        <button
          disabled={!attempts}
          onClick={onCheck}
          className="hack-terminal-main__check-btn"
        >
          {checkButtonText[siteLanguage]}
        </button>
      </div>
    </>
  );
};

const RulesTab = () => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  return (
    <div className="hack-terminal-main__text-wrapper">
      <p className="hack-terminal-main__text-content">
        <ol className="hack-terminal-main__list">
          <li className="hack-terminal-main__list-item">
            {yourTaskText[siteLanguage]}
          </li>
          <li className="hack-terminal-main__list-item">
            {enter6DigitsText[siteLanguage]}
          </li>
          <li className="hack-terminal-main__list-item">
            {afterCheckingText[siteLanguage]}
          </li>
          <ul className="hack-terminal-main__list hack-terminal-main__list-inner">
            <li className="hack-terminal-main__list-item">
              <span style={{ color: colors.blue }}>
                {greenDigitText[siteLanguage][0]}
              </span>
              {greenDigitText[siteLanguage][1]}
            </li>
            <li className="hack-terminal-main__list-item">
              <span style={{ color: colors.yellow }}>
                {yellowDigitText[siteLanguage][0]}
              </span>
              {yellowDigitText[siteLanguage][1]}
            </li>
            <li className="hack-terminal-main__list-item">
              <span style={{ color: colors.red }}>
                {redDigitText[siteLanguage][0]}
              </span>
              {redDigitText[siteLanguage][1]}
            </li>
          </ul>
          <li className="hack-terminal-main__list-item">
            {useHintsText[siteLanguage]}
          </li>
          <li className="hack-terminal-main__list-item">
            {ifYouGuessText[siteLanguage]}
          </li>
          <li className="hack-terminal-main__list-item">
            {attemptsEndedText[siteLanguage]}
          </li>
        </ol>
      </p>
    </div>
  );
};

const RewardsTab = () => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  return (
    <div className="hack-terminal-main__text-wrapper">
      <p className="hack-terminal-main__text-content">
        {whenYouWinText[siteLanguage]}
        <ul className="hack-terminal-main__list">
          <li className="hack-terminal-main__list-item">
            {resourcesFor15MinutesText[siteLanguage]} – 9,99%
          </li>
          <li className="hack-terminal-main__list-item">
            {resourcesFor30MinutesText[siteLanguage]} – 20%
          </li>
          <li className="hack-terminal-main__list-item">
            {resourcesFor45MinutesText[siteLanguage]} – 5%
          </li>
          <li className="hack-terminal-main__list-item">
            {freeBonusX2Text[siteLanguage]} – 20%
          </li>
          <li className="hack-terminal-main__list-item">
            0,001 {TMText[siteLanguage]} – 20%
          </li>
          <li className="hack-terminal-main__list-item">
            0,002 {TMText[siteLanguage]} – 12%
          </li>
          <li className="hack-terminal-main__list-item">
            0,003 {TMText[siteLanguage]} – 8%
          </li>
          <li className="hack-terminal-main__list-item">
            0,005 {TMText[siteLanguage]} – 4%
          </li>
          <li className="hack-terminal-main__list-item">
            0,01 {TMText[siteLanguage]} – 1%
          </li>
          <li className="hack-terminal-main__list-item">
            0,1 {TMText[siteLanguage]} – 0,01%
          </li>
        </ul>
      </p>
    </div>
  );
};

const ATEMPTS_COUNT = 6;
const HackTerminalMain = () => {
  const tg = useTelegram();
  const dispatch = useDispatch();
  const tgId = useSelector((state) => state.profile.tgData.tgId);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("main");
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const [modalOpened, setModalOpened] = useState(false);
  const [status, setStatus] = useState(0); // 0 , 1 (win), -1 (lose)
  const [activeAdIndex, setActiveAdIndex] = useState(0);
  const [adShowing, setAdShowing] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const initialWritedCode = Array.from({ length: ATEMPTS_COUNT })
    .map((_) => "0")
    .join("");
  const [writedCode, setWritedCode] = useState(initialWritedCode);
  const [attempts, setAttempts] = useState(6);
  const [history, setHistory] = useState([]);
  const [bonusType, setBonusType] = useState("");
  const [bonusValue, setBonusValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");

  // const onShowOnClickaAd = useOnClicka(() => {});
  const onShowSonarAd = useSonarAd();

  const isMobile = getPlatformType(tg);

  const showVideoAd = isMobile ? showInterstetialAdd : onShowSonarAd;
  // eslint-disable-next-line no-undef
  // const { frameContainerRef: frameContainerRef1, init: initAdstera1 } =
  //   useAdstera("b861226acddabb938b472d465270a255", "frame1");
  // const { frameContainerRef: frameContainerRef2, init: initAdstera2 } =
  //   useAdstera("540401b28df6a385df63b09cf0fdcb2b", "frame2");

  const ads = [
    {
      content: <div data-banner-id={onClickSpotId}></div>,
      function: () => initOnClicka(onClickSpotId),
    },
    // {
    //   content: (
    //     <div
    //       id="frame1"
    //       ref={frameContainerRef1}
    //       style={{ width: "320px", height: "50px" }}
    //     ></div>
    //   ),
    //   function: initAdstera1,
    // },
    // {
    //   content: (
    //     <div
    //       id="frame2"
    //       ref={frameContainerRef2}
    //       style={{ width: "160px", height: "300px" }}
    //     ></div>
    //   ),
    //   function: initAdstera2,
    // },
  ];

  const fetchCode = async () => {
    try {
      const code = await dispatch(fetchHacktermnialCode());
      setGeneratedCode(code);
    } catch (error) {
      setErrored(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tgId) {
      fetchCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgId]);

  // useEffect(() => {
  //   setAdShowing(false);

  //   setTimeout(() => {
  //     setAdShowing(true);
  //     setIsAnimating(false);
  //   }, 500);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeAdIndex]);

  useEffect(() => {
    if (adShowing) {
      ads[activeAdIndex].function();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adShowing]);

  const onChangeCode = (value, index) => {
    setWritedCode((prevState) => {
      const arr = prevState.split("");
      arr[index] = value;
      return arr.join("");
    });
  };

  const onCheck = async () => {
    if (!attempts) return;

    if (writedCode === generatedCode) {
      setLoading(true);
      const res = await dispatch(fetchHacktermnialReward());

      setBonusType(res.bonus_type);
      setBonusValue(res.bonus_value);
      setStatus(1);
      setModalOpened(true);
      setLoading(false);
      showVideoAd();
      return;
    }

    if (attempts - 1 <= 0) {
      setStatus(-1);
      setModalOpened(true);
      showVideoAd();
    }
    setAttempts((prevState) => prevState - 1);
    setHistory((prevState) => [writedCode, ...prevState]);

    // setIsAnimating(true);
    // setTimeout(() => {
    //   setActiveAdIndex((prevState) =>
    //     prevState === ads.length - 1 ? 0 : prevState + 1
    //   );
    // }, 500);
  };

  const onReset = async () => {
    setLoading(true);
    setModalOpened(false);
    setAttempts(ATEMPTS_COUNT);
    setHistory([]);
    setWritedCode(initialWritedCode);
    await fetchCode();
    setLoading(false);
  };

  const tabs = {
    main: {
      title: enterPasswordText,
      component: (
        <MainTab
          setActiveTab={setActiveTab}
          attempts={attempts}
          writedCode={writedCode}
          generatedCode={generatedCode}
          onChangeCode={onChangeCode}
          loading={loading}
          onCheck={onCheck}
          history={history}
          errored={errored}
        />
      ),
    },
    rules: {
      title: rulesOfTheGameText,
      component: <RulesTab />,
    },
    rewards: {
      title: whatYouCanWinText,
      component: <RewardsTab />,
    },
  };

  return (
    <section className="hack-terminal-main">
      <TransitionProvider
        inProp={loading}
        style={TransitionStyleTypes.opacity}
        className="hack-terminal-main__loader"
      ></TransitionProvider>
      <div className="hack-terminal-main__wrapper">
        <div className="hack-terminal-main__header">
          <div style={{ flex: 1 }}></div>
          <h3 className="hack-terminal-main__header-title">
            {hackingTerminalText[siteLanguage]}
          </h3>
          <div className="hack-terminal-main__close-btn-wrapper">
            <button
              onClick={() => {
                if (activeTab !== "main") {
                  setActiveTab("main");
                } else {
                  navigate(tasksPagePath);
                }
              }}
              className="hack-terminal-main__close-btn"
            >
              <Svg
                id={
                  activeTab !== "main"
                    ? hackTerminalBackIcon
                    : hackterminalCloseIcon
                }
              />
            </button>
          </div>
        </div>
        <div className="hack-terminal-main__game-wrapper">
          <div className="hack-terminal-main__options">
            <button
              onClick={() => setActiveTab("rewards")}
              className="hack-terminal-main__options-btn"
            >
              {prizeListText[siteLanguage]}
            </button>
            <button
              className="hack-terminal-main__options-btn"
              onClick={() => setActiveTab("rules")}
            >
              {rulesOfTheGameText[siteLanguage]}
            </button>
          </div>
          <h4 className="hack-terminal-main__game-title">
            {tabs[activeTab].title[siteLanguage]}
          </h4>
          {tabs[activeTab].component}
        </div>

        <div
          className={`hack-terminal-main__banner ${
            isAnimating ? "hidden" : ""
          }`}
        >
          {adShowing && ads[activeAdIndex].content}
        </div>
      </div>
      <HackTerminalModal
        activePopup={modalOpened}
        isLose={status < 0}
        bonusType={bonusType}
        bonusValue={bonusValue}
        onReset={onReset}
      />
    </section>
  );
};

export default HackTerminalMain;
