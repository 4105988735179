import React, { useState } from "react";
import "./BuildsList.scss";
import {
  getResIconImg,
} from "assets/images";
import ResourcesPopup from "components/ResourcesPopup/ResourcesPopup";
import { coinTypes } from "../../../constants/coinTypes";
import { adsgramBlocks } from "../../../constants/adsgram";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/formatNumber";
import { improoveBuilding } from "store/actions/buildings";
import { TRANSLATIONS } from "assets/translations";
import BuildsMatterPollModal from "../BuildsMatterPollModal/BuildsMatterPollModal";
import BuildsListItem from "./BuildListItem/BuildListItem";
import BuildsCasinoModal from "../BuildsCasinoModal/BuildsCasinoModal";

const upgradeIncomes = [
  ["1.25x", ""],
  ["1.25x", ""],
  ["1.25x", "1.01x"],
  ["1.25x", "1.05x"],
  ["1.25x", "1.1x"],
  ["1.25x", "1.2x"],
];



const {
  overallMiningSpeedText,
  hourText,
  freeText,
  improveText,
  upgradeText,
  viewAdText,
} = TRANSLATIONS.buildings;


const BuildsList = ({ activeTab }) => {
  const dispatch = useDispatch();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const builds = useSelector((state) => state.buildings.buildings);
  const summary_income = useSelector((state) => state.profile.summary_income);
  const [activeUpgradePopupIndex, setActiveUpgradePopupIndex] = useState(0);
  const [activeUpgradePopupId, setActiveUpgradePopupId] = useState(null);
  const [upgradePopupOpened, setUpgradePopupOpened] = useState(false);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [matterPollModalOpened, setMatterPollModalOpened] = useState(false);
  const [casinoModalOpened, setCasinoModalOpened] = useState(false);
  const stats = useSelector((state) => state.profile.stats);

  const openUpgradePopup = (upgradeIndex, id) => {
    setActiveUpgradePopupIndex(upgradeIndex + 1);
    setActiveUpgradePopupId(id);
    setUpgradePopupOpened(true);
  };

  const curBuild = builds.find(
    (build) => build.id_building === activeUpgradePopupId
  );

  const onUpgrade = async (useAd) => {
    setUpgradeLoading(true);
    if (activeUpgradePopupId) {
      await dispatch(
        improoveBuilding(
          activeUpgradePopupId,
          0,
          activeUpgradePopupIndex,
          useAd
        )
      );
    }
    setUpgradeLoading(false);
    setUpgradePopupOpened(false);
  };
  return (
    <>
      <div className="builds-list__summary_income">
        <span>{overallMiningSpeedText[siteLanguage]}</span>
        <strong>{formatNumber(summary_income)}</strong>
        <img src={getResIconImg} alt="resources" />
        <strong>/{hourText[siteLanguage]}</strong>
      </div>
      <ul className="builds-list">
        {builds
          .filter((item) => item.type === activeTab)
          .map((build) => (
            <BuildsListItem
              {...build}
              stats={stats}
              openUpgradePopup={openUpgradePopup}
              closeUpgradePopup={() => {
                setUpgradePopupOpened(false);
              }}
              key={build.id_building}
              setMatterPollModalOpened={setMatterPollModalOpened}
              setCasinoModalOpened={setCasinoModalOpened}
              siteLanguage={siteLanguage}
            />
          ))}

        {/* upgrade popup */}
        <ResourcesPopup
          activePopup={!!upgradePopupOpened}
          onClosePopup={() => setUpgradePopupOpened(false)}
          loading={upgradeLoading}
          title={`${upgradeText[siteLanguage]} ${activeUpgradePopupIndex}`}
          primaryBtnStats={[
            {
              text: formatNumber(+curBuild?.upgradeBuildingCost),
              type: coinTypes.resources,
            },
          ]}
          incomes={
            upgradeIncomes[activeUpgradePopupIndex - 1]
              ? upgradeIncomes[activeUpgradePopupIndex - 1]
                  .filter((item) => item)
                  .map((item) => ({ text: item, type: coinTypes.resources }))
              : []
          }
          adIncomes={[
            { text: freeText[siteLanguage], type: coinTypes.resources },
          ]}
          blockId={adsgramBlocks.buildingUpgrade}
          primaryBtnTxt={improveText[siteLanguage]}
          adbtnClass={"builds-list__adBtn"}
          primaryBtnClass={"builds-list__adBtn"}
          adBtnTxt={viewAdText[siteLanguage]}
          onClickPrimaryBtn={
            curBuild?.upgradeBuildingCost &&
            stats.resources >= curBuild?.upgradeBuildingCost
              ? onUpgrade
              : undefined
          }
          onClickAdBtn={() => onUpgrade(true)}
        />
        <BuildsMatterPollModal
          onClose={() => setMatterPollModalOpened(false)}
          activePopup={matterPollModalOpened}
        />
        <BuildsCasinoModal
          onClose={() => setCasinoModalOpened(false)}
          activePopup={casinoModalOpened}
        />
      </ul>
    </>
  );
};

export default BuildsList;
