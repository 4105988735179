import WalletForm from 'components/WalletPage/WalletForm/WalletForm'
import React from 'react'

const WalletPage = () => {
  return (
    <WalletForm/>
  )
}

export default WalletPage
