import { useRef } from "react";

export const useAdstera = (key, id) => {
  const frameContainerRef = useRef(null);

  const init = () => {
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.innerHTML = `
    atOptions = {
      'key' : '${key}',
      'format' : 'iframe',
      'height' : 250,
      'width' : 300,
      'params' : {}
    };
  `;

    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = `//www.highperformanceformat.com/${key}/invoke.js`;
    const el = id
      ? document.querySelector(`#${id}`)
      : frameContainerRef.current;
    if (el) {
      el?.appendChild(script1);
      el?.appendChild(script2);
    }
  };

  return { init, frameContainerRef };
};
