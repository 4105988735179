import { updateResetEnable, updateUserStats } from "store/slices/profileSlice";
import { fetchRequest } from "../tools/fetchTools";
import { getbuildings } from "store/slices/buildingsSlice";
import {
  fetchBonus,
  fetchMatterToTokenRate,
  fetchResetBonusValue,
  getBonusTypes,
} from "store/actions/profile";

const improveBuildingUrl = "building/";

export const improoveBuilding =
  (id, levels = 1, upgrade = 0, isWithAd) =>
  async (dispatch, getState) => {
    try {
      const tgId = getState().profile.tgData.tgId;
      const siteLanguage = getState().ui.siteLanguage;
      let data;
      if (!isWithAd) {
        data = await fetchRequest(
          improveBuildingUrl,
          tgId,
          siteLanguage,
          "PUT",
          {
            buildingId: id,
            levels,
            upgrade,
          }
        );
      } else {
        data = await fetchBonus(
          tgId,
          siteLanguage,
          getBonusTypes.upgrade,
          true,
          id
        );
      }

      if (data && data.account) {
        const {
          resources,
          matter,
          tokens,
          time_after_harvest,
          summary_income,
          value_resources_bonus,
          buildings,
          next_building,
          tasks,
          reset
        } = data.account;
        let buildingsPayload = [...buildings];
        if (next_building)
          buildingsPayload = [...buildingsPayload, ...next_building];

        dispatch(getbuildings(buildingsPayload));

        // update user stats
        dispatch(
          updateUserStats({
            resources,
            matter,
            tokens,
            time_after_harvest,
            summary_income,
            value_resources_bonus,
            subscribe: tasks.subscribe,
          })
        );

        if (id === 13) {
          dispatch(fetchMatterToTokenRate());
        }

        if(reset.enable) {
          dispatch(fetchResetBonusValue())
          dispatch(updateResetEnable(reset.enable))
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };
